import { NgModule } from '@angular/core';
import { HttpClientModule } from "@angular/common/http";
import { BrowserModule } from '@angular/platform-browser';


import {RedirectToBubbleComponent} from "./ui/redirect-to-bubble/redirect-to-bubble.component";

@NgModule({
  declarations: [
    RedirectToBubbleComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule
  ],
  providers: [],
  bootstrap: [RedirectToBubbleComponent]
})
export class BubbleModule { }
