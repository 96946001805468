import { Injectable } from '@angular/core';
import { format, getISOWeek } from 'date-fns';
import { FormattingService } from './formatting.service';

@Injectable({
  providedIn: 'root'
})
export class CalculationService {

  constructor(public formatService: FormattingService,
  ) { }

  getWeekNumber(year: number, month: number, day: number) {
    const date = new Date(year, month - 1, day);
    const weekNumber = getISOWeek(date);
    return weekNumber;
  }

  getCurrentWeekNumber() {
    const date = new Date();
    return getISOWeek(date);
  }

  getCurrentDate() {
    const date = new Date();
    const formattedDate = format(date, "yyyy-MM-dd");
    return formattedDate;
  }

  getStartDateOfWeek(date: string) {
    const inputDate = new Date(date);
    const dayOfWeek = inputDate.getDay();

    const startOfWeek = new Date(inputDate);
    startOfWeek.setDate(inputDate.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1));

    const endOfWeek = new Date(inputDate);
    endOfWeek.setDate(inputDate.getDate() - dayOfWeek + 7);

    return startOfWeek.toISOString().split('T')[0];
  }

  getEndDateOfWeek(date: string) {
    const inputDate = new Date(date);
    const dayOfWeek = inputDate.getDay();

    const startOfWeek = new Date(inputDate);
    startOfWeek.setDate(inputDate.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1));

    const endOfWeek = new Date(inputDate);
    endOfWeek.setDate(inputDate.getDate() - dayOfWeek + 7);
    return endOfWeek.toISOString().split('T')[0];
  }

  handleStringDateToWeekNumber(date: string) {
    return this.getWeekNumber(+date.slice(0, 4), +date.slice(5, 7), +date.slice(8, 10))
  }

  getWeekRange(weekNumber: number, year: number) {
    const januaryFirst = new Date(year, 0, 1);

    const daysToAdd = (1 - januaryFirst.getDay()) + (weekNumber - 1) * 7;
    const startOfWeek = new Date(januaryFirst);
    startOfWeek.setDate(januaryFirst.getDate() + daysToAdd);

    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(startOfWeek.getDate() + 6);

    const formatDate = (date: Date): string => {
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const year = date.getFullYear();
      return `${day}.${month}.${year}`;
    };

    const result = `${formatDate(startOfWeek)} - ${formatDate(endOfWeek)}`;
    return result;
  }

  calculateTotalDuration(items: any) {
    let duration = [];
    if (!!items) {
      for (let i = 0; i < items.length; i++) {
        let formattedValue = this.formatService.formatDuration(items[i].duration);
        if (!!formattedValue) {
          duration.push(Number(formattedValue))
        }
      }
      let sum = duration.length !== 0 ? duration.reduce((a: number, b: number) => a + b || 0) : 0
      return sum;
    }
    else return 0;
  }
}
