import {NgModule} from "@angular/core";
import {RouterModule, Routes} from "@angular/router";
import {VideoPage} from "../../features/ondemand/ui/video/video.page";
import {
  VideoDetailsPopupComponent
} from "../../features/ondemand/ui/library/video-details-popup/video-details-popup.component";
import {EmbeddedLibraryPage} from "./library/embedded-library.page";
import {EmbeddedHistoryPage} from "./history/embedded-history.page";

export const EMBEDDED_ROUTES : Routes = [
  {
    path: 'video',
    component: VideoPage
  },
  {
    path: 'video/:videoId',
    component: VideoPage
  },
  {
    path: 'library/videos',
    component: EmbeddedLibraryPage
  },
  {
    path: 'library/videos/:id',
    component: VideoDetailsPopupComponent
  },
  {
    path: 'history',
    component: EmbeddedHistoryPage
  },
];

@NgModule({
  imports: [RouterModule],
  exports: []
})
export class EmbeddedRoutesModule {

}
