import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  standalone: true,
  selector: 'app-close-element',
  templateUrl: './close-element.component.html',
  styleUrls: ['./close-element.component.scss']
})
export class CloseElementComponent {
  @Output() closePopup = new EventEmitter<void>();

  onClose() {
    this.closePopup.emit();
  }
}
