import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { LeadStatusDetails } from "../model/lead-status-details";
import { environment } from "../../../../environments/environment";
import { map, Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class LeadService {

  constructor(private http: HttpClient) { }

  public statusOf(token: string): Observable<LeadStatusDetails> {
    return this.http.get<LeadStatusDetails>(environment.api + "/v1/leads/status", { params: { "registration_token": token } });
  }

  requestToken(): Observable<string> {
    return this.http.post<{ value: string }>(environment.api + "/v1/leads/tokens", null)
      .pipe(map(token => token.value));
  }

}
