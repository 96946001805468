<app-library *ngIf="!selectedVideo"></app-library>

<div class="popup" *ngIf="video">
  <div class="popup-content">

    <div class="video-details">
      <div class="video-header">
        <h2>{{video.name}} </h2>

        <div class="video-details__flex">
          <div *ngIf="video.promotion(tags)?.length === 1" class="video-card__recommended recommended">
            <p>Empfehlung</p>
          </div>

          <div *ngIf="video.promotion(tags)?.length === 0"></div>

          <app-close-element (closePopup)="onGrandchildClose()"></app-close-element>
        </div>
      </div>

      <div class="video-details__main">
        <app-video-player class="video-details__player" [video]="video" [height]="videoHeight"></app-video-player>

        <div class="video-details__properties">
          <div *ngIf="canToggleFavorite()" (click)="toggleFavorite()" class="video-details__favorite-items">
            <p class="underline">Gefällt mir</p>
            <svg width="34" height="30" viewBox="0 0 34 30" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M31.4746 2.47464C34.5679 5.56792 34.6863 10.5454 31.7437 13.7823L16.9994 30.0002L2.25625 13.7823C-0.686403 10.5454 -0.567915 5.56797 2.52537 2.47468C5.97921 -0.979155 11.6674 -0.663952 14.7187 3.15017L17 6.00033L19.2793 3.15007C22.3306 -0.664058 28.0208 -0.979202 31.4746 2.47464Z"
                [attr.fill]="video.isFavorite() ? '#A63F49' : '#D9D9D9'" />
            </svg>
          </div>
          <p>{{video.description}}</p>

          <div class="video-details__wrapper">
            <div class="video-details__grid-item">
              <div class="video-details__grid-title">Trainer</div>
              <div class="video-details__flex-items">
                <img src="{{video.trainer().photoLink()}}" alt="">
                <div>{{video.trainer().name.given}}</div>
              </div>
            </div>
            <div class="video-details__grid-item">
              <div class="video-details__grid-title">Level</div>
              <div>Level {{video.level(tags)?.name}}</div>
            </div>
            <div class="video-details__grid-item">
              <div *ngIf="video.activityType(tags) || video.focus(tags)?.length || video.bodyZones(tags)?.length" class="
                  video-details__grid-title">Themen</div>
              <div class="video-details__bubbles">
                <ng-container *ngIf="video.activityType(tags)">
                  <span>{{video.activityType(tags)?.name}} </span>
                </ng-container>
                <ng-container *ngIf="video.focus(tags)?.length">
                  <div *ngFor="let focus of video.focus(tags)">
                    <span>{{focus.name}} </span>
                  </div>
                </ng-container>
                <ng-container *ngIf="video.bodyZones(tags)?.length">
                  <div *ngFor="let zone of video.bodyZones(tags)">
                    <span>{{zone.name}} </span>
                  </div>
                </ng-container>
              </div>
            </div>

            <div class="video-details__grid-item">
              <div class="video-details__grid-title">Du brauchst:</div>
              <div class="video-details__bubbles">
                <ng-container *ngIf="video.equipment(tags)?.length; else noEquipment">
                  <div *ngFor="let equip of video.equipment(tags)">
                    <span>{{equip.name}} </span>
                  </div>
                </ng-container>
                <ng-template #noEquipment>
                  <span class="videos__no-categories">(keine Ausrüstung)</span>
                </ng-template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
