import {Component, OnInit} from "@angular/core";
import {Router} from "@angular/router";
import {ApplicationStateService} from "../../state/application-state-service";
import {AuthenticationService} from "../../security/authentication-service";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {MyProfileService} from "../../services/my-profile.service";
import {APIResponse} from "../../model/api-response";
import {Customer} from "../../model/customer";

export const PATH_SETUP = "/setup";

@Component({
  selector: 'setup',
  templateUrl: './setup.page.html',
  styleUrls: ['./setup.page.scss']
})
export class SetupPage implements OnInit {

  accountForm: FormGroup;

  public success : boolean = false;
  public errorMessage : string | null = null;
  protected profile: Customer | null = null;
  private initFailed: boolean = false;

  constructor(private router : Router,
              private state : ApplicationStateService,
              private authenticator : AuthenticationService,
              private myProfile : MyProfileService,
              private formBuilder: FormBuilder) {
  this.accountForm = this.formBuilder.group({
    password: ['', [Validators.required, Validators.minLength(6)]],
    confirmPassword: ['', Validators.required]
  }, {
    validators: this.passwordMatchValidator
  });
  }

  // Custom validator to check if passwords match
  passwordMatchValidator(form: FormGroup) {
    const password = form.get('password')?.value;
    const confirmPassword = form.get('confirmPassword')?.value;
    return password === confirmPassword ? null : { mismatch: true };
  }

  ngOnInit(): void {
    this.myProfile.fetchProfile()
      .subscribe({
        next: (customer) => { this.profile = customer },
        error: (error) => { console.error(error); this.initFailed = true }
      });
  }

  submitPassword() {
    if (this.accountForm.invalid) {
      // If the form is invalid, display an error message
      alert('Bitte beheben Sie die Fehler im Formular.');
      return;
    }
    if (this.profile == null) {
      console.error("Page error: customer profile not initialized - API endpoint for password change is unknown.")
      return;
    }
    const password = this.accountForm.get('password')?.value;
    this.myProfile.changePassword(this.profile, password).subscribe({
      next: (response :APIResponse) => {
        if (response.isSuccess()) {
          this.success = true;
        } else {
          this.errorMessage = response.summary;
        }
      },
      error: (error) => { console.log(error); this.errorMessage = "Could not change password (unknown error)" }
    })
  }

  // Helper methods for form controls
  get password() {
    return this.accountForm.get('password');
  }
  get confirmPassword() {
    return this.accountForm.get('confirmPassword');
  }

  formVisible() {
    return !this.success;
  }

  successMessageVisible() : boolean {
    return this.success;
  }

  continue() {
    this.router.navigate(['library/videos']);
  }
}
