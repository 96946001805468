import {Resource} from "../api/hal/resource";

export class ErrorDetails {

  public readonly context : string;
  public readonly code : string;
  public readonly summary : string;

  constructor(json : any) {
    this.context = json['context'];
    this.code = json['code'];
    this.summary = json['summary'];
  }

}

export class APIResponse {

  public readonly id ?: string;
  public readonly sagaId ?: string;
  public readonly status : number;
  public readonly code : string;
  public readonly summary : string;
  public readonly errors ?: ErrorDetails[];

  constructor(json : any) {
    if (!!json) {
      this.id = json['id'] as string;
      this.sagaId = json['sagaId'] as string;
      this.status = json['status'] as number;
      this.code = json['code'] as string;
      this.summary = json['summary'] as string;
      this.errors = Resource.parseAll(json, "errors", ErrorDetails, false);
    } else {
      this.status = 0;
      this.code = 'unknown';
      this.summary = 'Response cannot be parsed';
    }
  }

  public isSuccess() : boolean {
    return this.status >= 200 && this.status < 300;
  }

}
