import { Injectable } from '@angular/core';
import { HistoryItem, HistoryItemWithVideoInfo, HistoryWeekItem, ItemInfo } from '../model/historyTypes';
import { CalculationService } from './calculation.service';
import { FormattingService } from './formatting.service';

@Injectable({
  providedIn: 'root'
})
export class HandlersService {

  constructor(
    public calcService: CalculationService,
    public formatService: FormattingService,
  ) { }


  addVideoInfo(data: HistoryItem[], ondemandArr: ItemInfo[], liveArr: ItemInfo[]): HistoryItemWithVideoInfo[] {
    if (!data || (!ondemandArr && !liveArr)) {
      return [];
    }

    return data.map((el) => {
      const ondemandData = ondemandArr ? ondemandArr.find((item) => item.uuid === el.contentId) : undefined;
      const liveData = liveArr ? liveArr.find((item) => item.uuid === el.contentId) : undefined;

      const videoInfo: Partial<HistoryItemWithVideoInfo> = {
        ...el,
        weekNumber: this.calcService.handleStringDateToWeekNumber(el.start.split('T')[0]),
        year: +el.start.slice(0, 4),
        watchedSeconds: +this.formatService.formatDurationInSeconds(el.duration),
        fullVideoDuration: ondemandData ? +this.formatService.formatDurationInSeconds(ondemandData.duration) : (liveData ? +this.formatService.formatDurationInSeconds(liveData.duration) : 0),
        name: (ondemandData?.name || liveData?.name) || '',
        shedulledStart: liveData ? liveData.start : undefined,
      };

      return videoInfo as HistoryItemWithVideoInfo;
    });
  }

  filterHistoryItemsByMinimalWatchedDuration(items: HistoryItemWithVideoInfo[] | null): null | HistoryItemWithVideoInfo[] {
    if (items !== null) {
      let copyItems = [...items];

      copyItems = copyItems.filter((el: HistoryItemWithVideoInfo) => {
        if (el.fullVideoDuration !== undefined) {

          return (el.fullVideoDuration > 300) ? el.watchedSeconds >= 300 : ((el.watchedSeconds / el.fullVideoDuration) * 100 > 50 && el.watchedSeconds > 60)
        }
        return null;
      })
      return copyItems;
    }
    return null;
  }

  groupByYear(arr: HistoryItemWithVideoInfo[] | null): HistoryItemWithVideoInfo[][] {
    const grouped = [];
    let currentGroup = [];
    if (!!arr) {
      for (let i = 0; i < arr.length; i++) {
        currentGroup.push(arr[i]);

        if (i === arr.length - 1 || arr[i].year !== arr[i + 1].year) {
          grouped.push(currentGroup);
          currentGroup = [];
        }
      }
    }
    return grouped;
  }

  fullfilArr(inputArray: HistoryItemWithVideoInfo[] | null): (HistoryItemWithVideoInfo | HistoryWeekItem)[] | null {
    if (inputArray !== null) {
      const resultArray = [];
      for (let i = 0; i < inputArray.length - 1; i++) {
        resultArray.push(inputArray[i]);
        const currentW = inputArray[i].weekNumber;
        const currentYear = inputArray[i].year;
        const nextW = inputArray[i + 1].weekNumber;

        if (nextW - currentW > 1) {
          for (let j = 1; j < nextW - currentW; j++) {
            resultArray.push({ weekNumber: currentW + j, year: currentYear });
          }
        }
      }
      resultArray.push(inputArray[inputArray.length - 1])
      return resultArray
    }
    return null;
  }

  groupByWeekNumber(arr: HistoryItemWithVideoInfo[][] | null): (HistoryItemWithVideoInfo | {
    weekNumber: number;
  })[][] {
    const grouped = [];
    if (!!arr) {
      for (let i = arr.length - 1; i >= 0; i--) {
        const fullfilledItems = this.fullfilArr(arr[i]);
        const yearItem: (HistoryItemWithVideoInfo | {
          weekNumber: number;
        })[] = !!fullfilledItems ? fullfilledItems.reverse() : arr[i].reverse();

        let currentGroup = [];
        for (let a = 0; a < yearItem.length; a++) {
          currentGroup.push(yearItem[a]);

          if (a === yearItem.length - 1 || yearItem[a].weekNumber !== yearItem[a + 1].weekNumber) {
            grouped.unshift(currentGroup);
            currentGroup = [];
          }
        }
      }
    }
    return grouped;
  }
}
