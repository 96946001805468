import { LookupData, onEmbedded, Resource } from "../../../core/api/hal/resource";
import { FAVORITES, Playlist } from "./playlist";
import { Predicate } from "../../../core/api/hal/util";
import { TrainerProfile } from "./trainer-profile";
import { ContentTag, TagGroup } from "../../../core/model/content-tag";
import { Duration } from "luxon";
import { REL_ALL_TRAINERS } from "./published-video-list";

//
// Data model relationships
//
export const REL_TRAINER = "trainer";
export const REL_VIDEO = "video";
export const REL_THUMBNAIL_IMAGE = "preview";
export const REL_PART_OF = "playlists:part-of";
export const REL_CONTENT_TAGS = "content:assigned-tags";

//
// Actions
//
export const REL_REMOVE_FAVORITE = "playlists:remove-favorite";
export const REL_ADD_FAVORITE = "playlists:add-favorite";
export const REL_LOG_ATTENDANCE = "my:attendance";

export class PublishedVideo extends Resource {

  public readonly id: string;
  public readonly order: number;
  public readonly name: string;
  public readonly description: string;

  private readonly duration: string;   // use #formattedDuration()
  private readonly tags: number[];     // use #tagsInGroup(group)
  private readonly externalId: string; // use #video()

  /**
   * Creates new OnDemandVideo from parsed JSON data, optionally using embedded resources from parent to get trainer,
   * playlists and content tags.
   * @param props deserialized JSON model of this resource
   * @param lookup optional lookup data to get the values, that are not embedded in this resource, but required (e.g. trainer)
   */
  constructor(props: any, lookup?: LookupData) {
    super(props,
      onEmbedded()
        .create(REL_PART_OF, Playlist)
        .create(REL_TRAINER, TrainerProfile)
        .create(REL_CONTENT_TAGS, ContentTag)
        .build(),
      lookup
    );
    this.id = props["id"] as string;
    this.id = props["id"] as string;
    this.order = props["order"] as number;
    this.name = props["name"] as string;
    this.description = props["description"] as string;
    this.duration = props["duration"] as string;
    this.tags = props["tags"] as number[];
    this.externalId = props["externalId"] as string;

    this.validate(REL_VIDEO, "Video player");
    this.validate(REL_THUMBNAIL_IMAGE, "Preview image");
    this.validate(REL_TRAINER, "Trainer information");

    this.validate(REL_LOG_ATTENDANCE, "Attendance logging API");
    if (!this.linksTo(REL_ADD_FAVORITE) && !this.linksTo(REL_REMOVE_FAVORITE)) {
      console.warn("Favorites feature not supported for video " + this.id);
    }
  }

  private validate(rel: string, message: string) {
    if (!this.linksTo(rel)) {
      console.warn(message + " not available for video " + this.id + " (missing link " + rel + ")");
    }
  }

  // Video description

  public video(): string {
    return this.link(REL_VIDEO).href;
  }

  public thumbnailImage(): string {
    return this.link(REL_THUMBNAIL_IMAGE).href;
  }

  public formattedDuration(): string {
    return Duration.fromISO(this.duration).toFormat("m':'ss");
  }

  public findTrainer(): TrainerProfile {
    const trainerLink = this.link(REL_TRAINER).href
    const trainer = this.findOne(REL_ALL_TRAINERS, (t: TrainerProfile) => t.self() === trainerLink)
    if (!trainer) {
      throw new Error(`Trainer not found for link: ${trainerLink}`);
    }
    return trainer;
  }

  public trainer(): TrainerProfile {
    return this.get(REL_TRAINER);
  }

  public assignedTags(): ContentTag[] {
    return this.findAll(REL_CONTENT_TAGS, tag => this.tags.includes(tag.id));
  }

  public tag(group: TagGroup, allTags: ContentTag[]): ContentTag | undefined {
    const result = ContentTag.filter(this.getTags(allTags), group);
    if (result.length != 1) return undefined;
    return result[0];
  }


  public getTags(allTags: ContentTag[]): ContentTag[] {
    if (!this.tags) return [];
    return allTags.filter(tag => this.tags!.includes(tag.id));
  }

  public tagsInGroup(group: TagGroup): ContentTag[] {
    return ContentTag.filter(this.assignedTags(), group);
  }

  public tagsOfGroup(group: TagGroup, allTags: ContentTag[]): ContentTag[] {
    return ContentTag.filter(this.getTags(allTags), group);
  }

  public activityType(allTags: ContentTag[]): ContentTag | undefined {
    return this.tag(TagGroup.TYPE, allTags);
  }

  public focus(allTags: ContentTag[]): ContentTag[] | undefined {
    return this.tagsOfGroup(TagGroup.FOCUS, allTags);
  }

  public level(allTags: ContentTag[]): ContentTag | undefined {
    return this.tag(TagGroup.EXERCISE_LEVEL, allTags);
  }

  public equipment(allTags: ContentTag[]): ContentTag[] | undefined {
    return this.tagsOfGroup(TagGroup.EQUIPMENT, allTags);
  }

  public bodyZones(allTags: ContentTag[]): ContentTag[] | undefined {
    return this.tagsOfGroup(TagGroup.BODY_ZONE, allTags);
  }

  public promotion(allTags: ContentTag[]): ContentTag[] | undefined {
    return this.tagsOfGroup(TagGroup.PROMOTION, allTags);
  }

  public isRecommended(): boolean {
    return this.tagsInGroup(TagGroup.PROMOTION).length == 1;
  }

  //
  // Favorites
  //

  public isPartOf(test: Predicate<Playlist>): boolean {
    if (!this.contains(REL_PART_OF)) return false;
    return !!this.findOne<Playlist>(REL_PART_OF, test);
  }

  public isFavorite(): boolean {
    return this.isPartOf(playlist => playlist.is(FAVORITES));
  }

  public playlists(): Playlist[] {
    return this.getAll(REL_PART_OF);
  }

  public attendanceLink(): string {
    return this.link(REL_LOG_ATTENDANCE).href;
  }
}
