export const environment = {
  api: "https://api.demo.my150minutes.com",
  bubble: "https://app.150minuten.de/version-test",
  typeform: "https://s9lkpzdgric.typeform.com",
  token: "?h=f02141cecd",
  stale_view_session: 60,
  loggingEnabled: true,
  client_id: "app",
  client_secret: "demo"
};
