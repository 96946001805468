import {AuthenticationDetails} from "./authentication-details";
import {Resource} from "../api/hal/resource";
import {Customer} from "./customer";
import {Authentication} from "./authentication";

export class Introspection {
  public readonly active : boolean;
  public readonly clientId : string;
  public readonly username: string;
  public readonly exp : number;
  public readonly iat : number;
  public readonly details : AuthenticationDetails;

  constructor(props : any) {
    this.active = props["active"] as boolean;
    this.clientId = props["clientId"] as string;
    this.username = props["username"] as string;
    this.exp = props["exp"] as number;
    this.iat = props["iat"] as number;
    this.details = Resource.parseRequired(props, 'details', AuthenticationDetails);
  }

  public asAuthentication(token : string) : Authentication {
    return new Authentication({
      "access_token" : token,
      "expires" : this.exp,
      "scope" : "customer",
      "details" : this.details
    });
  }

  public currentUser() : Customer {
    return this.details.principal;
  }

}
