export class Contact {

  public readonly value : string;
  private readonly verified : string | undefined | null;

  constructor(props: any) {
    this.value = props["value"] as string;
    this.verified = props["verified"] as string | null | undefined;
  }

  public isVerified() : boolean {
    return !!this.verified;
  }

  public timestamp() : Date {
    if (!this.verified) throw new Error("Contact not verified: invoke isVerified() first!");
    return new Date(this.verified);
  }

}
