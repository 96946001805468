import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { environment } from "../../../../../environments/environment";
import { DOCUMENT } from "@angular/common";
import { LeadService } from "../../services/lead.service";

@Component({
  selector: 'typeformRedirect',
  templateUrl: './redirect-to-typeform.component.html',
  styleUrls: ['./redirect-to-typeform.component.scss'],
  providers: [LeadService]
})
export class RedirectToTypeformComponent implements OnInit {

  constructor(private route: ActivatedRoute,
    private leads: LeadService,
    @Inject(DOCUMENT) private document: Document) {
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      const queryParams: { [key: string]: string } = {};
      for (let paramKey in params) {
        if (paramKey !== 'form' && paramKey != 'registration_token') {
          queryParams[paramKey] = params[paramKey];
        }
      }
      const form = params['form'];
      if (!!form) {
        const token = params['registration_token'];
        if (!token) {
          this.leads.requestToken().subscribe(token => this.performRedirect(form, token, queryParams));
        } else {
          this.performRedirect(form, token, queryParams);
        }
      } else {
        console.log("Form ID (?form=...) not specified in page URL.");
      }
    })
  }


  private performRedirect(form: string, token: string, queryParams: { [key: string]: string }) {
    const uri = new URL(environment.typeform + "/to/" + form);
    uri.searchParams.append("registration_token", token);
    for (const key in queryParams) {
      uri.searchParams.append(key, queryParams[key]);
    }
    this.document.location.href = uri.href;
  }

}
