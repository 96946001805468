import { Component } from '@angular/core';

@Component({
  selector: 'history-page',
  templateUrl: './history.page.html',
  styleUrls: ['./history.page.scss']
})
export class HistoryPage {

}
