import {NgModule} from "@angular/core";
import {BrowserModule} from "@angular/platform-browser";
import {HttpClientModule} from "@angular/common/http";

import {VideoPage} from "./ui/video/video.page";
import {LibraryView} from "./ui/library/library-view/library-view.component";
import {FilterPopupComponent} from "./ui/library/filter-popup/filter-popup.component";
import {VideoCardComponent} from "./ui/library/video-card/video-card.component";
import {VideoDetailsPopupComponent} from "./ui/library/video-details-popup/video-details-popup.component";
import {VideoPlayerComponent} from "./ui/video-player/video-player.component";
import {CloseElementComponent} from "../../patterns/close-element/close-element.component";
import {CustomSelectComponent} from "../../patterns/select/custom-select.component";
import {LibraryPage} from "./ui/library/library.page";

@NgModule({
  declarations: [
    VideoPage,
    LibraryView,
    VideoCardComponent,
    VideoDetailsPopupComponent,
    VideoPlayerComponent,
    FilterPopupComponent,
    LibraryPage
  ],
  exports: [
    LibraryView
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    CloseElementComponent,
    CustomSelectComponent
  ]
})
export class OnDemandModule { }
