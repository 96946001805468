export class SessionState {

  public model : { [key : string] : any } = {}
  private embedded: boolean = false;

  constructor(json?: any) {
    if (json && json["model"] && !Array.isArray(json["model"])) {
      for (const key of json) {
        if (json.hasOwnProperty(key)) this.model[key] = json[key];
      }
    }
  }

  public contains(key : string) : boolean {
    return this.model[key] !== undefined;
  }

  public get<T>(key : string, ctor? : { new(json: any) : T }) : T | null | undefined {
    let value = this.model[key];
    if ((value !== undefined && value !== null) && !!ctor) {
      if (!!ctor) return new ctor(value);
    }
    return value;
  }

  public set<T>(key : string, value : T) : SessionState {
    this.model[key] = value;
    return this;
  }

  static fromJson(json: any): SessionState {
    return new SessionState(json);
  }

  asEmbedded() : SessionState {
    this.embedded = true;
    return this;
  }

  asStandalone() : SessionState {
    this.embedded = false;
    return this;
  }

  isEmbedded() {
    return this.embedded;
  }
}
