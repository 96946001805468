import {APIClient} from "../api/api-client";
import {Injectable} from "@angular/core";
import {ApplicationStateService} from "../state/application-state-service";
import {Observable, of} from "rxjs";
import {Participation} from "../../features/ondemand/model/participation";
import {environment} from "../../../environments/environment";
import {Customer} from "../model/customer";
import {APIResponse} from "../model/api-response";
import {map} from "rxjs/operators";

export const REL_MY_PROFILE = "my:profile";
export const REL_CHANGE_PASSWORD = "my:password";

@Injectable({
  providedIn: 'root'
})
export class MyProfileService {

  constructor(private api: APIClient,
              private state : ApplicationStateService) {}

  public fetchProfile() : Observable<Customer> {
    let link = this.state.application().current().api().link(REL_MY_PROFILE);
    if (!link) throw new Error("Profile link expected but not found");
    return this.api.get(link.href, Customer);
  }

  public changePassword(profile : Customer, password: string) : Observable<APIResponse> {
    let body = { password: password };
    let changePasswordLink = profile.link(REL_CHANGE_PASSWORD).href;
    return this.api.post(changePasswordLink, body, APIResponse)
      .pipe(map(response => {
        if (!response.isSuccess()) console.log(response);
        return response;
      }));
  }

}
