import {PublishedVideo} from "./published-video";
import {parseTemplate} from "../../../core/api/hal/templates";
import {Playlist} from "./playlist";
import {TrainerProfile} from "./trainer-profile";
import {ContentTag} from "../../../core/model/content-tag";

export class PublishedVideoQuery {

  constructor(
    public readonly playlists?: string[] | undefined,
    public readonly trainers?: string[]  | undefined,
    public readonly tags?: number[]  | undefined,
    public readonly minDuration?: number | undefined,
    public readonly maxDuration?: number | undefined) {
  }

  public expand(template: string): string {
    return parseTemplate(template).expandAll({
      "playlists": this.playlists,
      "trainers": this.trainers,
      "tags": this.tags?.map(id => id.toString()),
      "min_length" : this.minDuration,
      "max_length" : this.maxDuration
    });
  }

  public accepts(video: PublishedVideo): boolean {

    function anyMatch<T, K>(identifiers: K[] | undefined, items: T[], idOf: (item: T) => K): boolean {
      if (! identifiers || identifiers.length < 1) return true;
      for (let item of items) {
        for (let id of identifiers) {
          if (id === idOf(item)) {
            return true;
          }
        }
      }
      return false;
    }

    return anyMatch<Playlist, string>(this.playlists, video.playlists(), v => v.id)
      && anyMatch<TrainerProfile, string>(this.trainers, [video.trainer()], t => t.id)
      && anyMatch<ContentTag, number>(this.tags, video.assignedTags(), t => t.id);
  }

  isOnlyFavorites() {
    return !this.trainers && !this.tags && !!this.playlists && !this.minDuration && !this.maxDuration;
  }

  isEmpty() {
    return !this.trainers && !this.tags && !this.playlists && !this.minDuration && !this.maxDuration;
  }
}
