<section *ngIf="showHistory" class="history history--mobile">
  <h2>Deine Trainingshistorie</h2>
  <div *ngIf="!preparedItems || !preparedItems.length">
    <div class="history__week-head">
      <h4> KW {{getCurrentWeekNumber()}}, {{currentWeekRange}}</h4>
      <h4>Trainingsdauer dieser Woche: 0 min</h4>
    </div>
    <div class="history__items">
      <div class="history__item">
        <p class="history__empty">Keine Trainings in dieser Woche.</p>
      </div>
    </div>
  </div>

  <div *ngIf="!!items">
    <div class="history__week" *ngFor="let item of preparedItems">
      <div class="history__week-head">
        <h4 *ngIf="item && item[0]?.start; else noInfo"> KW {{item[0].weekNumber}},
          {{startWeekDate(item[0].start)}} - {{endWeekDate(item[0].start)}}
        </h4>

        <ng-template #noInfo>
          <h4> KW {{item[0].weekNumber}}, {{getWeekRange(item[0].weekNumber, item[0].year)}} </h4>
        </ng-template>

        <h4>{{calculateTotalDuration(item)}} min </h4>
      </div>

      <div class="history__items">
        <div class="history__item" *ngFor="let item of item">
          <h5 *ngIf="item && item?.start; else noHistory">{{formatCustomDate(getDatePartFromIsoFormat(item.start))}},
            {{ item.type === 'ONDEMAND'? startWatching(item.start):
            startWatching(item.shedulledStart) }}
          </h5>
          <p class="history__name" *ngIf="item && item?.name">{{ item.name}} </p>
          <div *ngIf="item && item?.type" class="history__details">
            <p>{{ item.type === 'ONDEMAND'? 'Mediathek': 'Live' }}</p>
            <p>{{ formatDuration(item.duration) }} min </p>
          </div>
          <ng-template #noHistory>
            <p class="history__empty">Keine Trainings in dieser Woche.</p>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</section>

<section *ngIf="showHistory" class="history history--desktop">
  <h2>Deine Trainingshistorie</h2>

  <div *ngIf="!preparedItems || !preparedItems.length">
    <div class="history__week-head">
      <h4> KW {{getCurrentWeekNumber()}}, {{currentWeekRange}}</h4>
      <h4>Trainingsdauer dieser Woche: 0 min</h4>
    </div>
    <div class="history__items">
      <div class="history__item">
        <p class="history__empty">Keine Trainings in dieser Woche.</p>
      </div>
    </div>
  </div>

  <div *ngIf="!!items">

    <div class="history__week" *ngFor="let item of preparedItems">
      <div class="history__week-head">
        <h4 *ngIf="item && item[0]?.start; else noInfo"> KW {{item[0].weekNumber}},
          {{startWeekDate(item[0].start)}} - {{endWeekDate(item[0].start)}}
        </h4>

        <ng-template #noInfo>
          <h4> KW {{item[0].weekNumber}}, {{getWeekRange(item[0].weekNumber,
            item[0].year)}}</h4>
        </ng-template>

        <h4> {{calculateTotalDuration(item)}} min </h4>
      </div>
      <table *ngIf="item && item[0]?.start; else noHistory">
        <tbody>
          <tr *ngFor="let item of item">
            <td class="history__line history__line--fixed-13">{{formatCustomDate(convertTimeZone(item.start).split('
              ')[0])}}</td>
            <td class="history__line history__line--fixed-7">{{ item.type === 'ONDEMAND'?
              startWatching(item.start) : startWatching(item.shedulledStart) }}
            </td>
            <td class="history__name history__line history__line--fixed-59">{{ item.name}} </td>
            <td class="history__line history__line--fixed-11">{{ item.type === 'ONDEMAND'? 'Mediathek': 'Live' }}</td>
            <td class="history__line history__line--right history__line--fixed-9">{{ formatDuration(item.duration) }}
              min
            </td>
          </tr>
        </tbody>
      </table>
      <ng-template #noHistory>
        <div class="history__items">
          <div class="history__item">
            <p class="history__empty">Keine Trainings in dieser Woche.</p>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</section>

<div class="bottom-bar">
  <div class="bottom-bar_total">
    <h5 *ngIf="!isSmallScreen">Trainingsdauer gesamt: {{ calculateTotalDuration(filteredItems) }} min</h5>
    <h5 *ngIf="isSmallScreen">Gesamt: {{ calculateTotalDuration(filteredItems) }} min</h5>
  </div>
</div>
