import {Component} from "@angular/core";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AuthenticationService} from "../../security/authentication-service";

@Component({
  selector: 'recovery',
  templateUrl: './recovery.page.html',
  styleUrls: ['./recovery.page.scss']
})
export class RecoveryPage {

  recoveryForm: FormGroup;
  protected success : boolean = false;

  constructor(private formBuilder: FormBuilder,
              private auth : AuthenticationService) {
    this.recoveryForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }

  submitRecovery() {
    if (this.recoveryForm.invalid) {
      alert('Bitte geben Sie eine gültige E-Mail-Adresse ein.');
      return;
    }

    // Handle form submission (password recovery logic)
    const email = this.recoveryForm.get('email')?.value;
    this.auth.resetPassword(email).subscribe(success => this.success = success);
  }

  // Helper method to access form control
  get email() {
    return this.recoveryForm.get('email');
  }

}
