<div class="wrapper">
  <div class="container">

    <div class="logo">
      <img
        src="https://images.ctfassets.net/et2zhvrxomut/4PtVB5tq1KR3EaBpb8NXu6/ae59553442ac2ddc2b25bd20bbf7c117/150minuten-logo-image.png"
        alt="150Minten Logo">
    </div>

    <div class="content">
      <h1>Wir haben festgestellt, dass bereits ein 150Minuten-Konto mit dieser E-Mail-Adresse verbunden ist.</h1>

      <ul  class="items">
        <li>Auf der nächsten Seite kannst Du Dich mit dieser E-Mail-Adresse direkt anmelden.</li>
        <li> Alternativ kannst Du Dein Passwort zurücksetzen, wenn Du auf "PASSWORT VERGESSEN" klickst.</li>
        <li>Oder Du klickst auf "Du hast noch kein Konto?", wenn Du Dich mit einer anderen E-Mail-Adresse registrieren
          möchtest.</li>
      </ul>
    </div>

    <div>
      <a href="https://app.150minuten.de/login" class="cta">Weiter</a>
    </div>

  </div>
</div>
