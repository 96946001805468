<div class="container">
  <div class="logo">
    <img src="assets/images/logo.png" alt="150 Minuten | Firmenlogo">
  </div>

  <div *ngIf="!success">
  <h1>Passwort wiederherstellen</h1>
  <p>Bitte geben Sie Ihre E-Mail-Adresse ein, um Ihr Passwort zurückzusetzen.</p>

  <form [formGroup]="recoveryForm" (ngSubmit)="submitRecovery()">
    <div class="form-group">
      <label for="email">E-Mail:</label>
      <input type="email" id="email" formControlName="email">
      <!-- Validation Error for Email -->
      <div *ngIf="email?.invalid && (email?.dirty || email?.touched)" class="error-message">
        <small *ngIf="email?.errors?.['required']">E-Mail ist erforderlich.</small>
        <small *ngIf="email?.errors?.['email']">Bitte geben Sie eine gültige E-Mail-Adresse ein.</small>
      </div>
    </div>

    <button type="submit" class="submit-btn" [disabled]="recoveryForm.invalid">Passwort wiederherstellen</button>
  </form>
  </div>
  <div *ngIf="success" class="success-info">
    <h1>Nächster Schritt</h1>
    <p>Wenn Ihr Konto existiert, erhalten Sie in Kürze eine E-Mail mit einem Anmeldelink.</p>
    <p>Klicken Sie auf den Link, um sich anzumelden und ein neues Passwort festzulegen.</p>
    <p>Wir freuen uns darauf, Sie bald beim Erkunden unserer großartigen Medienbibliothek zu sehen!</p>
  </div>

  <div class="footer">
    <a href="https://150minuten.de/impressum" target="_blank">Impressum</a>
    <a href="https://150minuten.de/agb" target="_blank">AGB</a>
    <a href="https://150minuten.de/datenschutz" target="_blank">Datenschutz</a>
  </div>

</div>
