import {Component} from '@angular/core';
import {ApplicationStateService} from "../../core/state/application-state-service";

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss']
})
export class MainLayoutComponent {

  constructor(state : ApplicationStateService) {
    state.currentTab().update(state => state.asStandalone());
  }

}
