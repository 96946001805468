export const ROUTE_LOGIN = "login";

export const ROUTE_RECOVER_PASSWORD = "recovery";
export const ROUTE_CURRENT_USER = "";

export const ROUTE_MY_HOME = ROUTE_CURRENT_USER + "home";
export const ROUTE_MY_PROFILE = ROUTE_CURRENT_USER + "profile";
export const ROUTE_MY_LIBRARY = ROUTE_CURRENT_USER + "library/videos";
export const ROUTE_MY_SCHEDULE  = ROUTE_CURRENT_USER + "schedule";
export const ROUTE_MY_HISTORY  = ROUTE_CURRENT_USER + "history";

export const ROUTE_EMBEDDED = "embedded/";
export const ROUTE_EMBEDDED_LIBRARY = ROUTE_EMBEDDED + ROUTE_MY_LIBRARY;
export const ROUTE_EMBEDDED_HISTORY = ROUTE_EMBEDDED + ROUTE_MY_HISTORY;
