import { Component, computed, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ContentTag, TagGroup } from 'src/app/core/model/content-tag';
import { TrainerProfile } from '../../../model/trainer-profile';
import { Playlist } from "../../../model/playlist";
import { PublishedVideoQuery } from "../../../model/published-video-query";
import { SelectValue } from "../../../../../patterns/select/select.value";

function filter(tags: ContentTag[], group: TagGroup | TagGroup[]): SelectValue[] {
  return tags.filter((el: ContentTag) => Array.isArray(group) ? group.includes(el.group) : el.group === group).map(tag => new SelectValue(tag.id, tag.name, tag.icon))
}

const SHORT_VIDEOS = "1,15";

const MEDIUM_VIDEOS = "15,25";

const LONG_VIDEOS = "25,180";

@Component({
  selector: 'app-filter-popup',
  templateUrl: './filter-popup.component.html',
  styleUrls: ['./filter-popup.component.scss']
})
export class FilterPopupComponent implements OnInit {

  @Input() tags: ContentTag[] = [];
  @Input() trainers: TrainerProfile[] = [];
  @Input() favorites: Playlist | undefined;
  @Input() query: PublishedVideoQuery | undefined;

  playlists = computed(() => {
    const fav = this.favorites;
    return fav ? [new SelectValue(fav.id, "Nur Favoriten")] : [];
  });

  durations = [
    new SelectValue(SHORT_VIDEOS, "bis 15 min"),
    new SelectValue(MEDIUM_VIDEOS, "15-25 min"),
    new SelectValue(LONG_VIDEOS, "ab 25 min")
  ];
  bodyZoneOptions = computed(() => filter(this.tags, TagGroup.BODY_ZONE));
  topicOptions = computed(() => filter(this.tags, [TagGroup.TYPE, TagGroup.FOCUS, TagGroup.CUSTOM]));
  levelOptions = computed(() => filter(this.tags, TagGroup.EXERCISE_LEVEL));
  trainerOptions = computed(() => this.trainers.map(trainer => new SelectValue(trainer.id, trainer.name.given)));

  @Output() onApply = new EventEmitter<PublishedVideoQuery>();
  @Output() onCancel = new EventEmitter<boolean>();

  selectedDuration: string[] | undefined;
  minDuration: number | undefined;
  maxDuration: number | undefined;
  selectedTags: {
    [key: string]: number[] | undefined;
  } = {};
  selectedTrainers: string[] | undefined;
  selectedPlaylists: string[] | undefined;

  ngOnInit(): void {
    document.body.style.overflow = 'hidden';

    const q = this.query;
    if (q) {
      this.selectedPlaylists = q.playlists;
      this.selectedTrainers = q.trainers;
      if (q.tags) {
        this.preSelect(q.tags, this.topicOptions(), 'topic');
        this.preSelect(q.tags, this.bodyZoneOptions(), 'zone');
        this.preSelect(q.tags, this.levelOptions(), 'level');
      }
      if (q.minDuration !== undefined && q.maxDuration !== undefined) {
        this.minDuration = q.minDuration;
        this.maxDuration = q.maxDuration;
        switch (this.minDuration) {
          case 1: this.selectedDuration = [SHORT_VIDEOS]; break;
          case 15: this.selectedDuration = [MEDIUM_VIDEOS]; break;
          case 25: this.selectedDuration = [LONG_VIDEOS]; break;
        }
      } else {
        this.selectedDuration = undefined;
      }
    }
  }

  private preSelect(tags: number[], values: SelectValue[], group: string) {
    for (const tag of tags) {
      if (values.filter(item => item.id === tag).length > 0) {
        if (!!this.selectedTags[group]) {
          this.selectedTags[group]?.push(tag);
        } else {
          this.selectedTags[group] = [tag];
        }
      }
    }
  }

  applyFilter(): void {
    const tags = [];
    for (const group in this.selectedTags) {
      if (this.selectedTags.hasOwnProperty(group) && !!this.selectedTags[group]) {
        for (const item of this.selectedTags[group] as number[]) tags.push(item);
      }
    }
    this.onApply.emit(new PublishedVideoQuery(this.selectedPlaylists, this.selectedTrainers, tags.length > 0 ? tags : undefined, this.minDuration, this.maxDuration));
  }

  hideFilterPopup(): void {
    this.onCancel.emit(true);
  }

  reset(): void {
    this.selectedDuration = undefined;
    this.minDuration = undefined;
    this.maxDuration = undefined;
    this.selectedTags = {};
    this.selectedTrainers = undefined;
    this.selectedPlaylists = undefined;
  }

  selectDuration(selection: (string | number)[] | undefined) {
    this.selectedDuration = selection as string[];
    if (!!selection && selection.length == 1) {
      let values = (selection[0] as string).split(",");
      const min = Number.parseInt(values[0]);
      const max = Number.parseInt(values[1]);
      this.minDuration = min;
      this.maxDuration = max;
    } else {
      this.minDuration = undefined;
      this.maxDuration = undefined;
    }
  }

  selectTags(group: string, selection: (string | number)[] | undefined) {
    this.selectedTags[group] = selection as number[] | undefined;
  }

  selectTrainers(selection: (string | number)[] | undefined) {
    this.selectedTrainers = selection as string[] | undefined;
  }

  selectPlaylist(selection: (string | number)[] | undefined) {
    this.selectedPlaylists = selection as string[] | undefined;
  }

  ngOnDestroy(): void {
    document.body.style.overflow = '';
  }
}
