import {Component} from "@angular/core";

@Component({
  selector: 'recovery',
  templateUrl: './recovery.page.html',
  styleUrls: ['./recovery.page.scss']
})
export class RecoveryPage {

}
