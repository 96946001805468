<div class="container">
  <div class="logo">
    <img src="assets/images/logo.png" alt="150 Minuten | Firmenlogo">
  </div>
  <h1>Hallo {{profile?.name?.given}}</h1>
  <div id="form-wrapper" *ngIf="formVisible()">
    <p>Um Ihr Konto zu aktivieren, setzen Sie bitte ein&nbsp;Passwort.</p>
    <form [formGroup]="accountForm" (ngSubmit)="submitPassword()">
      <div class="form-group">
        <label for="password">Neues Passwort:</label>
        <input type="password" id="password" formControlName="password">
        <!-- Validation Error for Password -->
        <div *ngIf="password?.invalid && (password?.dirty || password?.touched)" class="error-message">
          <small *ngIf="password?.errors?.['required']">Passwort ist erforderlich.</small>
          <small *ngIf="password?.errors?.['minlength']">Passwort muss mindestens 6 Zeichen lang sein.</small>
        </div>
      </div>

      <div class="form-group">
        <label for="confirm-password">Passwort bestätigen:</label>
        <input type="password" id="confirm-password" formControlName="confirmPassword">
        <!-- Validation Error for Confirm Password -->
        <div *ngIf="confirmPassword?.invalid && (confirmPassword?.dirty || confirmPassword?.touched)" class="error-message">
          <small *ngIf="confirmPassword?.errors?.['required']">Bestätigung des Passworts ist erforderlich.</small>
          <small *ngIf="accountForm.errors?.['mismatch'] && confirmPassword?.touched">Passwörter stimmen nicht überein.</small>
        </div>
      </div>
      <button type="submit" class="submit-btn" [disabled]="accountForm.invalid">Passwort setzen</button>
      <a class="action" (click)="continue()">Überspringen</a>
    </form>
  </div>
  <div *ngIf="successMessageVisible()">
      <p>Ihr Passwort wurde erfolgreich festgelegt. Jetzt können Sie unsere Mediathek erkunden.</p>
      <button class="submit-btn" (click)="continue()">Weiter zur Mediathek</button>
  </div>

  <div class="footer">
    <a href="https://150minuten.de/impressum" target="_blank">Impressum</a>
    <a href="https://150minuten.de/agb" target="_blank">AGB</a>
    <a href="https://150minuten.de/datenschutz" target="_blank">Datenschutz</a>
  </div>
</div>
