import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-embedded-library-page',
  templateUrl: './embedded-library.page.html',
  styleUrls:[ './embedded-library.page.scss']
})
export class EmbeddedLibraryPage implements OnInit {

  constructor(route : ActivatedRoute) {
  }

  ngOnInit(): void {
  }

}
