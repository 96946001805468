<section class="library">
  <div class="library__filter-pannel">
    <div class="library__filter-items">
      <div class="filter-item " [ngClass]="{'filter-item--checked': presetValue === 'all'}"
        (click)="toggleFilterPreset('all')">
        Alle
      </div>

      <div class="filter-item " [ngClass]="{'filter-item--checked': presetValue === 'fav'}"
        (click)="toggleFilterPreset('fav')">
        Nur Favoriten
      </div>
      <div *ngIf="presetValue === 'user'" class="filter-item "
        [ngClass]="{'filter-item--checked': presetValue === 'user'}" (click)="toggleFilterPreset('user')">
        Benutzerdefinierte
      </div>
    </div>

    <div (click)="openFilterPopup()" class="library__open-filters">
      <p class="underline">Weitere Filter </p>

      <svg width="25" height="19" viewBox="0 0 25 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_366_6051)">
          <path d="M8.05042 17L17.126 17M5.02521 9.5H20.1513M2 2H23.1765" stroke="#541D26" stroke-width="3"
            stroke-linecap="round" stroke-linejoin="round" />
        </g>
        <defs>
          <clipPath id="clip0_366_6051">
            <rect width="25" height="19" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  </div>

  <div class="library__results">
    <p>{{videos.length}} Ergebnisse</p>
  </div>

  <div class="library__video-list">
    <div *ngFor="let video of videos">
      <app-video-card [video]="video" [tags]="tags" [tags]="tags"
        (videoClicked)="openVideoDetails($event)"></app-video-card>
    </div>
  </div>
</section>

<app-filter-popup *ngIf="showFilterPopup && !!favorites" [query]="query.getValue()" [tags]="tags" [trainers]="trainers"
  [favorites]="favorites" (onApply)="applyFilter($event)" (onCancel)="closeFilter()"></app-filter-popup>

<app-video-details-popup *ngIf="showVideoDetailsPopup" (closePopup)="handleClosePopup()"
  [selectedVideo]="selectedVideo"></app-video-details-popup>
