<div class="popup">
  <div class="popup-content">
    <div class="filter">
      <app-close-element (closePopup)="hideFilterPopup()"></app-close-element>

      <h2>Trainings-Filter</h2>

      <div class="filter__options">

        <app-custom-select [title]="'Favoriten'"   [options]="playlists()" [defaultOption]="'Alle'" [selection]="selectedPlaylists"     (selectionChange)="selectPlaylist($event)"></app-custom-select>
        <app-custom-select [title]="'Dauer'"       [options]="durations"                            [selection]="selectedDuration"      (selectionChange)="selectDuration($event)"></app-custom-select>
        <app-custom-select [title]="'Level'"       [options]="levelOptions()"    [multi]="true"     [selection]="selectedTags['level']" (selectionChange)="selectTags('level', $event)"></app-custom-select>

        <app-custom-select [title]="'Themen'"      [options]="topicOptions()"    [multi]="true"     [selection]="selectedTags['topic']" (selectionChange)="selectTags('topic', $event)"></app-custom-select>
        <app-custom-select [title]="'Körperzonen'" [options]="bodyZoneOptions()" [multi]="true"     [selection]="selectedTags['zone']"  (selectionChange)="selectTags('zone', $event)"></app-custom-select>

        <app-custom-select [title]="'Trainer'"    [options]="trainerOptions()"  [multi]="true"     [selection]="selectedTrainers"      (selectionChange)="selectTrainers($event)"></app-custom-select>

      </div>

      <a class="filter__button underline" (click)="reset()">Zurücksetzen und alle Videos anzeigen</a>
    </div>

    <div class="filter__actions">
      <a class="filter__action filter__action--primary" (click)="applyFilter()">Speichern</a>
      <a class="filter__action filter__action--secondary" (click)="hideFilterPopup()">Abbrechen</a>
    </div>
  </div>
</div>
