import {Component} from '@angular/core';

@Component({
  selector: 'app-history.page',
  templateUrl: './embedded-history.page.html',
  styleUrls: ['./embedded-history.page.scss']
})
export class EmbeddedHistoryPage {

}
