<div class="filter__option">
  <p>{{title}}</p>
  <div class="filter__items">
    <div class="filter-item filter__item" *ngIf="!!defaultOption"
      [ngClass]="{'filter-item--checked': isEmptySelection()}" (click)="reset()">
      {{defaultOption}}
    </div>

    <div class="filter-item filter__item" *ngFor="let item of options"
      [ngClass]="{'filter-item--checked': isSelected(item)}" (click)="select(item)">
      {{item.name}}
      <svg *ngIf="!defaultOption" width="14" height="15" viewBox="0 0 14 15" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path d="M13 13.5L1 1.5M13 1.5L1 13.5" stroke="white" stroke-width="2" stroke-linecap="round"
          stroke-linejoin="round" />
      </svg>
    </div>
  </div>
</div>
