export class Address {

  public readonly countryCode : string | undefined;
  public readonly postalCode : string | undefined;
  public readonly region : string | undefined;
  public readonly settlement : string | undefined;
  public readonly streetAddress : string | undefined;
  public readonly addressDetails : string | undefined;

  public constructor(props: any) {
    this.countryCode = props["countryCode"] as string | undefined;
    this.postalCode = props["postalCode"] as string | undefined;
    this.region = props["region"] as string | undefined;
    this.settlement = props["settlement"] as string | undefined;
    this.streetAddress = props["streetAddress"] as string | undefined;
    this.addressDetails = props["addressDetails"] as string | undefined;
  }

}
