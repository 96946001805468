import {onLookup, Resources} from "../../../core/api/hal/resources";
import {PublishedVideo, REL_CONTENT_TAGS, REL_TRAINER} from "./published-video";
import {onEmbedded} from "../../../core/api/hal/resource";
import {ContentTag} from "../../../core/model/content-tag";
import {TrainerProfile} from "./trainer-profile";
import {FAVORITES, Playlist} from "./playlist";


export const REL_ALL_CONTENT_TAGS = "content:tags";
export const REL_ALL_TRAINERS = "trainers:all";
export const REL_MY_PLAYLISTS = "my:playlists";

export class PublishedVideoList extends Resources<PublishedVideo> {

  constructor(props: any) {
    super(props,
      PublishedVideo,
      onEmbedded() // create objects for embedded content
        .create(REL_ALL_CONTENT_TAGS, ContentTag)
        .create(REL_ALL_TRAINERS, TrainerProfile)
        .create(REL_MY_PLAYLISTS, Playlist)
        .build(),
      onLookup() // initialize all videos with embedded content from this list
        .map(REL_TRAINER, REL_ALL_TRAINERS)
        .map(REL_CONTENT_TAGS, REL_ALL_CONTENT_TAGS)
        .build());
  }

  public favorites() : Playlist {
    const playlist = this.findOne(REL_MY_PLAYLISTS, (playlist : Playlist) => playlist.is(FAVORITES));
    if (!playlist) throw new Error("Favorites playlist is not found");
    return playlist;
  }

  public allTrainers() : TrainerProfile[] {
    return this.getAll(REL_ALL_TRAINERS);
  }

  public allTags() : ContentTag[] {
    return this.getAll(REL_ALL_CONTENT_TAGS);
  }

}
