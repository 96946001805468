<div class="layout main">
  <nav>
    <app-main-menu></app-main-menu>
  </nav>

  <main>
    <header>
      <app-page-header></app-page-header>
    </header>
    <div class="page">
      <router-outlet></router-outlet>
    </div>
    <footer>
      <app-page-footer></app-page-footer>
    </footer>
  </main>
</div>
