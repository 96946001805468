import {Resource} from "../api/hal/resource";

export const PROMOTION = 'PROMOTION';
export const EXERCISE_LEVEL = 'EXERCISE_LEVEL';
export const BODY_ZONE = 'BODY_ZONE';
export const FOCUS = 'FOCUS';
export const TYPE = 'TYPE';
export const EQUIPMENT = 'EQUIPMENT';
export const CUSTOM = 'CUSTOM';

export enum TagGroup {
  PROMOTION = 'PROMOTION',
  EXERCISE_LEVEL = 'EXERCISE_LEVEL',
  BODY_ZONE = 'BODY_ZONE',
  FOCUS = 'FOCUS',
  TYPE = 'TYPE',
  EQUIPMENT = 'EQUIPMENT',
  CUSTOM = 'CUSTOM',
}

export class ContentTag extends Resource {

  public readonly id: number;
  public readonly name: TagGroup;
  public readonly description: string | undefined;
  public readonly icon: string | undefined;
  public readonly group: TagGroup;

  constructor(json: any) {
    super(json);
    this.id = json['id'] as number;
    this.name = json['name'] as TagGroup;
    this.description = json['description'] as string;
    this.icon = json['icon'] as string;
    this.group = json['group'] as TagGroup;
  }

  public hasDescription(): boolean {
    return !!this.description;
  }

  public hasIcon(): boolean {
    return !!this.icon;
  }

  static filter(tags: ContentTag[] | undefined, group: TagGroup) {
    if (tags !== undefined) {
      return tags.filter((element, index, array) => { return element.group === group; });
    } else return [];
  }
}
