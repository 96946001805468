import {BehaviorSubject, Observable, Subject} from "rxjs";

const STORED_STATE_KEY = "state";

export class StateStorage<T> {

  private observable: Subject<T>;
  private currentState!: T;

  constructor(private readonly storage: Storage,
    private readonly fromJson: (json: any) => T
  ) {
    this.currentState = fromJson({});
    this.observable = new BehaviorSubject<T>(this.currentState);
  }

  public current(): T {
    return this.currentState;
  }

  public asObservable(): Observable<T> {
    return this.observable;
  }

  public init(): void {
    const json = this.storage.getItem(STORED_STATE_KEY);
    if (json) {
      this.currentState = this.fromJson(JSON.parse(json));
    } else {
      this.currentState = this.fromJson({});
    }
    this.observable.next(this.currentState);
  }

  public next(state: T): T {
    this.currentState = state;
    this.storage.setItem(STORED_STATE_KEY, JSON.stringify(state));
    this.observable.next(state);
    return state;
  }

  public clear(): void {
    this.storage.clear();
    this.next(this.fromJson({}));
  }

  update(modify: (state : T) => T) {
    return this.next(modify(this.currentState));
  }

}
