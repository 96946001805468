import {AuthenticationDetails} from "./authentication-details";
import {Resource} from "../api/hal/resource";
import {Customer} from "./customer";

export class Authentication {

  public readonly access_token: string;
  public readonly refresh_token: string | undefined;
  public readonly expires: number;
  public readonly scope : string;
  public readonly details : AuthenticationDetails;

  constructor(json: any) {
    this.access_token = json['access_token'] as string;
    this.refresh_token = json['refresh_token'] as string;
    this.scope = json['scope'] as string;
    const expiration = json['expires_in'] as number;
    this.expires = Date.now() + expiration * 1000;
    this.details = Resource.parseRequired(json, 'details', AuthenticationDetails);
  }

  public isExpired() : boolean {
    return Date.now() > this.expires;
  }

  public currentUser() : Customer {
    return this.details.principal;
  }
}
