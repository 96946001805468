import {BehaviorSubject, Observable} from "rxjs";
import {HeaderModel} from "./header-model";

export class HeaderDataService {

  private model: BehaviorSubject<HeaderModel> = new BehaviorSubject<HeaderModel>(new HeaderModel("Wilkommen!", null));

  data() : Observable<HeaderModel> {
    return this.model;
  }

  update(title: string, subtitle: string | null) {
    this.model.next(new HeaderModel(title, subtitle))
  }
}
