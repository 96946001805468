import {Customer} from "../model/customer";
import {Resource} from "../api/hal/resource";
import {Authentication} from "../model/authentication";
import {ApiEntry} from "../model/api-entry";

export class ApplicationState {

  constructor(
    public readonly authentication: Authentication | undefined,
    public readonly customer: Customer | undefined,
    public readonly entry: ApiEntry | undefined) {
  }

  static fromJson(json: any): ApplicationState {
    if (json) {
      const authentication = json.hasOwnProperty('authentication') ? Resource.parseOptional(json, "authentication", Authentication, true) : undefined;
      const user = json.hasOwnProperty('customer') ? Resource.parseOptional(json, "customer", Customer, true) : undefined;
      const entry = json.hasOwnProperty('entry') ? Resource.parseOptional(json, "entry", ApiEntry, true) : undefined;
      return new ApplicationState(authentication, user, entry);
    } else {
      throw new Error("Json is null or undefined");
    }
  }

  public isAuthenticated(): boolean {
    return !!this.authentication;
  }

  public isInitialized(): boolean {
    return !!this.entry;
  }

  public auth(): Authentication {
    if (!!this.authentication) return this.authentication;
    throw new Error("Not authenticated: use isAuthenticated() first!");
  }

  currentUser() {
    if (!!this.customer) return this.customer;
    throw new Error("Current user not initialized");
  }

  public api(): ApiEntry {
    if (!!this.entry) return this.entry;
    throw new Error("Not initialized: use isInitialized() first!");
  }

  public onUpdate(entry: ApiEntry): ApplicationState {
    return new ApplicationState(this.authentication, this.customer, entry);
  }

  public onProfileUpdate(user: Customer): ApplicationState {
    return new ApplicationState(this.authentication, user, this.entry);
  }

  expectFullyInitialized(): ApplicationState {
    if (!this.authentication) throw new Error("Not authenticated: did user log out in another tab?")
    if (!this.customer) throw new Error("Customer not initialized")
    if (!this.entry) throw new Error("API not initialized")
    return this;
  }
}
