import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {HistoryService} from 'src/app/features/history/services/history.service';
import {ActivatedRoute} from '@angular/router';
import {LegacySessionStateService} from 'src/app/core/deprecated/legacy-session-state.service';
import {CalculationService} from '../../../services/calculation.service';
import {HistoryItem, HistoryItemWithVideoInfo, ItemInfo} from '../../../model/historyTypes';
import {FormattingService} from '../../../services/formatting.service';
import {HandlersService} from '../../../services/handlers.service';

const REL_ON_DEMAND_HISTORY = "ondemand";

const REL_LIVE_HISTORY = "live";

@Component({
  selector: 'app-history',
  templateUrl: './history-view.component.html',
  styleUrls: ['./history-view.component.scss']
})

export class HistoryViewComponent implements OnInit, OnDestroy {
  items: HistoryItem[] | undefined = [];
  timezone!: string | undefined;
  itemsWithVideoInfo: HistoryItemWithVideoInfo[] | null = [];
  groupedByWeekNumber: HistoryItemWithVideoInfo[][][] | null = null;
  groupedByYear: HistoryItemWithVideoInfo[][] | null = null;
  groupedFullfilledItems: any | undefined;
  preparedItems: any | undefined;
  filteredItems: HistoryItemWithVideoInfo[] | null = null;
  ondemandArr: ItemInfo[] = [];
  liveArr: ItemInfo[] = [];
  public showHistory: boolean = true;
  currentWeekRange: string | undefined;
  isSmallScreen: boolean | undefined;

  constructor(
    private historyService: HistoryService,
    private route: ActivatedRoute,
    private state : LegacySessionStateService,
    public calcService: CalculationService,
    public formatService: FormattingService,
    public handler: HandlersService,
  ) { }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.checkScreenSize();
  }
  checkScreenSize() {
    this.isSmallScreen = window.innerWidth < 600;
  }

  ngOnInit(): void {
    this.checkScreenSize();
    this.retrieveHistoryData();
  }

  retrieveHistoryData(): void {
    this.historyService.fetchHistoryWithToken().subscribe((data) => {
      this.items = data.elements();
      this.items && this.items.sort((a, b) => a.start.localeCompare(b.start));
      this.ondemandArr = data.contains(REL_ON_DEMAND_HISTORY) ? data.getAll(REL_ON_DEMAND_HISTORY) : [];
      this.liveArr = data.contains(REL_LIVE_HISTORY) ? data.getAll(REL_LIVE_HISTORY) : [];
      this.itemsWithVideoInfo = this.items && this.addVideoInfo(this.items, this.ondemandArr, this.liveArr) || null;
      this.filteredItems = this.itemsWithVideoInfo && this.handler.filterHistoryItemsByMinimalWatchedDuration(this.itemsWithVideoInfo) || null;
      this.groupedByYear = this.filteredItems ? this.handler.groupByYear(this.filteredItems) : null;
      this.preparedItems = this.groupedByYear ? this.handler.groupByWeekNumber(this.groupedByYear).reverse() : null;
      this.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      this.state.set('timeZone:', !!this.timezone ? this.timezone : 'Europe/Berlin');
      this.currentWeekRange = this.formatWeekRange(this.getCurrentDate());
    });
  }

  calculateTotalDuration(items: any) {
    return this.calcService.calculateTotalDuration(items);
  }

  formatWeekRange(date: string): string {
    return `${this.formatMonthAndDayDate(this.getStartDateOfWeek(date))} - ${this.formatMonthAndDayDate(this.getEndDateOfWeek(date))}`;
  }

  addVideoInfo(data: HistoryItem[], ondemandArr: ItemInfo[], liveArr: ItemInfo[]) {
    return this.handler.addVideoInfo(data, ondemandArr, liveArr)
  }

  startWeekDate(item: string) {
    return this.formatMonthAndDayDate(this.getStartDateOfWeek(this.getDatePartFromIsoFormat(item)))
  }

  endWeekDate(item: string) {
    return this.formatMonthAndDayDate(this.getEndDateOfWeek(this.getDatePartFromIsoFormat(item)))
  }

  startWatching(value: string) {
    return this.convertTimeZone(value).split(' ')[1].slice(0, 5)
  }

  formatDuration(value: string | null) {
    return this.formatService.formatDuration(value)
  }

  getDurationInSeconds(value: string | undefined) {
    return this.formatService.formatDurationInSeconds(value)
  }

  findOndemandData(arr: ItemInfo[], item: HistoryItem) {
    return arr.find((el: ItemInfo) => el.uuid === item.contentId)
  }

  findLiveData(arr: ItemInfo[], item: HistoryItem) {
    return arr.find((el: ItemInfo) => el.uuid === item.contentId)
  }

  formatCustomDate(inputDate: string) {
    return this.formatService.formatCustomDate(inputDate);
  }

  formatMonthAndDayDate(inputDate: string) {
    return this.formatService.formatMonthAndDayDate(inputDate);
  }

  getWeekNumber(year: number, month: number, day: number) {
    return this.calcService.getWeekNumber(year, month, day);
  }

  getCurrentWeekNumber() {
    return this.calcService.getCurrentWeekNumber();
  }

  getCurrentDate() {
    return this.calcService.getCurrentDate();
  }

  getStartDateOfWeek(date: string) {
    return this.calcService.getStartDateOfWeek(date);
  }

  getEndDateOfWeek(date: string) {
    return this.calcService.getEndDateOfWeek(date);
  }

  convertTimeZone(timeStr: string) {
    return this.formatService.convertTimeZone(timeStr);
  }

  getDatePartFromIsoFormat(date: string) {
    return date.split('T')[0]
  }

  getWeekRange(weekNumber: number, year: number) {
    return this.calcService.getWeekRange(weekNumber, year);
  }

  ngOnDestroy(): void { }
}
