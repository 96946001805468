import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-emailcheck',
  templateUrl: './emailcheck.component.html',
  styleUrls: ['./emailcheck.component.scss']
})
export class EmailcheckComponent implements OnInit {
  constructor(
  ) {
  }

  ngOnInit() {
    setTimeout(() => {
        window.location.href = 'https://app.150minuten.de/login'
    }, 15000);
  }
}
