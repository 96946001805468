import {Resource} from "../api/hal/resource";

export class ApiEntry extends Resource {

  public readonly envName: string;

  constructor(props : any) {
    super(props);
    this.envName = !props['envName'] ? 'demo' : props['envName'];
  }


}
