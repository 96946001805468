import {Component} from "@angular/core";
import {Router} from "@angular/router";
import {ApplicationStateService} from "../../state/application-state-service";
import {AuthenticationService} from "../../security/authentication-service";
import {Observable} from "rxjs";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ROUTE_MY_HOME, ROUTE_RECOVER_PASSWORD} from "../../navigation";

@Component({
  selector: 'login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss']
})
export class LoginPage {

  private loginForm !: FormGroup;
  private showError: boolean = false;
  private emailValue: string = "";

  constructor(private router : Router,
              private state : ApplicationStateService,
              private authenticator : AuthenticationService) {
  }

  ngOnInit(): void {
    const isAuthenticated = this.authenticator.isAuthenticated();
    if (isAuthenticated instanceof Observable) {
      isAuthenticated.subscribe(success => {
        this.router.navigate([ "/" + ROUTE_MY_HOME ])
      })
    } else if (isAuthenticated) {
      this.router.navigate([ "/" + ROUTE_MY_HOME ]);
    }
    this.loginForm = new FormGroup({
      'email': new FormControl("", [Validators.required, Validators.email]),
      'password': new FormControl("", [
        Validators.required,
      ]),
    })
  }

  onInput() {
    this.showError = false;
    this.emailValue = this.loginForm.get("email")?.value;
  }
  submitLogin() {
    const { email, password } = this.loginForm.value;

    this.authenticator.login(email, password).subscribe({
      next: (success) => {
        if (success) {
          this.router.navigate([ ROUTE_MY_HOME ])
        } else {
          this.showError = true;
        }
      },
      error: (error) => { console.log(error); this.showError = true }
    })
  }

  resetPassword() {
    this.router.navigate([ ROUTE_RECOVER_PASSWORD ], { state: { email: this.emailValue } })
  }

}
