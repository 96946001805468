import { Component, OnDestroy, OnInit } from '@angular/core';
import { HeaderDataService } from "../../core/state/header-data.service";
import { ApplicationStateService } from "../../core/state/application-state-service";
import { Customer } from "../../core/model/customer";
import { WindowService } from 'src/app/features/ondemand/ui/window.service';
import { Subscription } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss']
})

export class PageHeaderComponent implements OnInit, OnDestroy {

  title: string | null = null;
  subtitle: string | null = null;
  private customer: Customer | undefined;
  loading: boolean = true;
  isSmallScreen: boolean = false;
  private resizeSubscription: Subscription | undefined;
  envUrl: string;

  constructor(
    private header: HeaderDataService,
    private state: ApplicationStateService,
    private windowService: WindowService,
    private router: Router
  ) {
    this.envUrl = environment.bubble;

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.closeMenu();
      }
    });

    window.addEventListener('scroll', this.closeMenuOnScroll.bind(this));


    this.header.data().subscribe(model => {
      this.title = model.title;
      this.subtitle = model.subtitle;
    });
    this.state.application().asObservable().subscribe(state => {
      this.loading = !state.customer;
      this.customer = state.customer;
    })
  }

  ngOnInit() {
    this.resizeSubscription = this.windowService.windowWidth$.subscribe(width => {
      this.isSmallScreen = width <= 1024;
    });
  }

  isTrial(): boolean {
    return !!this.customer?.subscription?.terms.isTrial();
  }

  subscriptionLevel(): string {
    if (this.customer && this.customer.subscription) return this.customer.subscription.terms.level();
    return "FREE";
  }

  expires(): number {
    if (this.customer && this.customer.subscription) return this.customer.subscription.terms.remainingDays();
    return 0;
  }

  isPaid() {
    return !!this.customer?.subscription?.terms.isPaid();
  }

  openMenu() {
    document.getElementsByClassName('mobile-menu-overlay')[0]?.classList.add('open');
  }

  closeMenu() {
    document.getElementsByClassName('mobile-menu-overlay')[0]?.classList.remove('open');
  }

  private closeMenuOnScroll() {
    document.getElementsByClassName('mobile-menu-overlay')[0]?.classList.remove('open');
  }

  ngOnDestroy() {
    if (this.resizeSubscription) {
      this.resizeSubscription.unsubscribe();

      window.removeEventListener('scroll', this.closeMenuOnScroll.bind(this));
    }
  }
}
