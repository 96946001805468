import {Resources} from "../../../core/api/hal/resources";
import {HistoryElement} from "./history-element";

export class TrainingHistory extends Resources<HistoryElement> {

  constructor(props : any) {
    super(props, HistoryElement);
  }

}
