import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { environment } from "../../../../../environments/environment";
import { DOCUMENT } from "@angular/common";
import { LeadService } from "../../services/lead.service";
import { HttpClient } from '@angular/common/http';

const MAX_ATTEMPTS = 5;

@Component({
  selector: 'performRedirect',
  templateUrl: './redirect-to-bubble.component.html',
  styleUrls: ['./redirect-to-bubble.component.scss'],
  providers: [LeadService]
})
export class RedirectToBubbleComponent implements OnInit {
  newToken: any | undefined

  constructor(private route: ActivatedRoute,
    private leads: LeadService,
    private http: HttpClient,
    @Inject(DOCUMENT) private document: Document) {
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      const token = params['registration_token'];
      if (!!token) {
        // check status of the lead before redirecting to Bubble
        this.scheduleStatusCheck(token, 1);
      } else {
        console.log("Registration token (?registration_token=...) not specified in page URL. Displaying error message...");
      }
    })
  }

  private scheduleStatusCheck(token: string, attempt: number) {
    setTimeout(() => {
      this.leads.statusOf(token).subscribe(details => {
        if (details.status === "EMAIL_ALREADY_EXIST") {
          window.location.href = 'https://app-new.150minuten.de/emailcheck';
        }
        if (details.status === "COMPLETE_REGISTRATION") {
          this.completeRegistration(token)
        }
        if (details.status === "PASSWORD_RESET") {
          // lead is ready: redirect user to Bubble
          this.performRedirect(token);
        } else if (attempt < MAX_ATTEMPTS) {
          // lead is not ready to continue registration: try again later if we did not exceed maximum number of attempts
          this.scheduleStatusCheck(token, attempt + 1);
        }
      });
    }, 500 * attempt);
  }

  private performRedirect(token: string) {
    this.document.location.href = environment.bubble + "/password?registration_token=" + token;
  }

  private completeRegistration(token: string) {
    this.http.get<any>(environment.api + '/v1/leads/complete/registration?token=' + token, {}).subscribe({
      next: (response: any) => {
        const newToken = response.token;
        if (newToken) {
          this.performRedirect(newToken);
        } else {
          console.error("Response from complete registration endpoint does not contain a token.");
        }
      },
      error: (error: any) => {
        console.error("Error completing registration:", error);
      }
    });
  }
}
