import {HALLink, REL_SELF} from "./hal-link";
import {HALCollection, mapCollection} from "./hal-collection";
import {Predicate} from "./util";

export interface EmbeddedMapping {
  [key : string] : (props: any) => any
}
export interface LookupData {
  add(key : string, values : Resource[]) : void;
  lookup(key : string) : Resource[] | undefined;
}

export class EmbeddedMapper {
  mapping : EmbeddedMapping;
  constructor(factories?: EmbeddedMapping) {
    this.mapping = factories ? factories : {};
  }
  public create<T>(rel : string, ctor: new (body: any) => T) : EmbeddedMapper {
    this.mapping[rel] = props => new ctor(props);
    return this;
  }
  public execute<T>(rel : string, builder: (body: any) => T) : EmbeddedMapper {
    this.mapping[rel] = builder;
    return this;
  }
  build() : EmbeddedMapping {
    return this.mapping;
  }
}
export function onEmbedded(mapping?: EmbeddedMapping) : EmbeddedMapper {
  return new EmbeddedMapper(mapping);
}



export class Resource {

  protected readonly _links: HALCollection<HALLink>;
  protected readonly _embedded: HALCollection<any>;
  protected readonly _lookupData : LookupData | undefined;

  constructor(json: any,
              mapping?: EmbeddedMapping,
              lookupData?: LookupData) {
    this._links = mapCollection(json["_links"], props => new HALLink(props));
    this._embedded = mapCollection<any>(json["_embedded"], (props, key) => {
      return mapping && mapping[key] ? mapping[key](props) : props;
    });
    for (const key in this._embedded) {
      const props = this._embedded[key];
      lookupData?.add(key, props);
    }
    this._lookupData = lookupData;
  }

  public self(): string {
    if (!this._links[REL_SELF]) {
      throw new Error("Resource does not have 'self' link");
    }
    if (this._links[REL_SELF].length != 1) {
      throw new Error("Exactly one 'self' link is expected, was " + this._links[REL_SELF].length);
    }

    return this._links[REL_SELF][0].href;
  }

  public linksTo(rel: string): boolean {
    return !!this._links[rel];
  }

  public link(rel: string, test ?: Predicate<HALLink>) : HALLink {
    let links = this._links[rel] && this._links[rel];
    if (!links) throw new Error("Expected link " + rel + " not found.");
    if (!!test) {
      links = links.filter(test);
    }
    if (links.length < 1)  throw new Error("Link " + rel + " matching given criteria not found.");
    return links[0];
  }

  public contains(rel: string): boolean {
    return !!this._embedded[rel];
  }

  private static map<T>(props: any, ctor?: new (body: any) => T, fn?: (body: any) => T) : T {
    return !!ctor ? new ctor(props) : (!!fn ? fn(props) : props);
  }

  private getRaw(rel: string) : any[] {
    let props : any[] | undefined = this._embedded[rel];
    if (!props && !!this._lookupData) {
      props = this._lookupData.lookup(rel);
    }
    if (!props || (props.length == 0)) throw new Error("Expected value " + rel + " not found.");
    return props;
  }

  public getAll<T>(rel: string, ctor?: new (body: any) => T, fn?: (body: any) => T) : T[] {
    let props = this.getRaw(rel);
    return props.map<T>(value => Resource.map(value, ctor, fn));
  }

  public findAll<T>(rel: string, test : Predicate<T>, ctor?: new (body: any) => T, fn?: (body: any) => T) : T[] {
    return this.getAll(rel, ctor, fn).filter(test);
  }

  public get<T>(rel: string, ctor?: new (body: any) => T, fn?: (body: any) => T) : T {
    const props = this.getRaw(rel)[0];
    return Resource.map(props, ctor, fn);
  }

  public findOne<T>(rel: string, test : Predicate<T>, ctor?: new (body: any) => T, fn?: (body: any) => T) : T | undefined {
    const items = this.getAll(rel, ctor, fn).filter(test);
    return items.length > 0 ? items[0] : undefined;
  }

  public static parseRequired<T>(model: any, name: string, ctor: new (body: any) => T): T {
    const parsedValue = Resource.parseOptional(model, name, ctor, true);
    if (parsedValue !== undefined) {
      return parsedValue;
    } else {
      throw new Error(name + " is required");
    }
  }

  public static parseOptional<T>(model: any, name: string, ctor: new (body: any) => T, required: boolean = false, message?: string): T | undefined {
    const raw = model[name];
    if (required || !!raw) {
      if (!raw) { throw new Error(!message ? `model[${name}] is null or undefined` : message) }
      return new ctor(raw);
    } else {
      return undefined;
    }
  }

  public static parseValue<T>(model: any, name: string, factory: (body: any) => T, required: boolean): T | undefined {
    const raw = model[name];
    if (required || !!raw) {
      return factory(raw);
    } else {
      return undefined;
    }
  }

  public static parseAll<T>(model: any, name: string, ctor: new (body: any) => T, required: boolean): T[] | undefined {
    const raw = model[name];
    if (required || !!raw) {
      return Resource.toArray(raw, ctor);
    } else {
      return undefined;
    }
  }

  public static toArray<T>(items: any, ctor: new (body: any) => T): T[] {
    if (Array.isArray(items)) {
      const result: T[] = [];
      for (let i = 0; i < items.length; i++) {
        try {
          const value = new ctor(items[i]);
          result.push(value)
        }
        catch (error) { console.log(error) }
      }
      return result;
    } else {
      return [new ctor(items)];
    }
  }

}
