import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SelectValue} from './select.value';
import {CommonModule} from "@angular/common";

@Component({
  standalone: true,
  selector: 'app-custom-select',
  templateUrl: './custom-select.component.html',
  styleUrls: ['./custom-select.component.scss'],
  imports : [ CommonModule ]
})
export class CustomSelectComponent implements OnInit {

  /** name of the input */
  @Input() title!: string;
  /** optional value for "nothing selected" */
  @Input() defaultOption?: string;
  /** if true, this is a multi-select */
  @Input() multi?: boolean;
  /** list of options to display */
  @Input() options!: SelectValue[];
  /** Current selection: empty selection is represented by undefined value */
  @Input() selection!: (string | number)[] | undefined;
  /** Event emitter for selection changes */
  @Output() selectionChange = new EventEmitter<(string | number)[] | undefined>();

  ngOnInit(): void {
  }

  isSelected(item: SelectValue): boolean {
    const items = this.selection;
    if (!items) return false;
    return items.includes(item.id);
  }

  reset(): void {
    this.selection = undefined;
    this.selectionChange.emit(this.selection);
  }

  select(item: SelectValue): void {
    let items = this.selection || [];
    const itemIndex = items.indexOf(item.id);

    if (itemIndex > -1) {
      // Item is already selected: remove it from selection
      items = items.filter(id => id !== item.id);
      this.selection = items.length > 0 ? items : undefined;
    } else {
      // item was not selected, check how we should add it to selection
      if (!this.multi) {
        // single-select: replace current selection with new item
        this.selection = [item.id];
      } else {
        // multi-select: add item to the selection
        items.push(item.id);
        this.selection = items;
      }
    }

    this.selectionChange.emit(this.selection);
  }

  isEmptySelection(): boolean {
    const items = this.selection;
    return !items || items.length === 0;
  }
}
