import {Resource} from "../../../core/api/hal/resource";

export const FAVORITES = "FAVORITES";
export type PlaylistType = "FAVORITES" | "PERSONAL_PLAN";

export class Playlist extends Resource {

  public readonly id: string;
  public readonly type: PlaylistType;
  public readonly name: string | undefined;
  public readonly customerId: string | undefined;

  constructor(props: any) {
    super(props);
    this.id = props["id"] as string;
    this.name = props["name"] as string | undefined;
    this.type = props["type"] as PlaylistType;
    this.customerId = props["customerId"] as string | undefined;
  }

  is(type: string) : boolean {
    return this.type === type;
  }

  public isPersonal() : boolean {
    return this.customerId !== undefined;
  }

}
