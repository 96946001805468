import {NgModule} from '@angular/core';
import {HttpClientModule} from "@angular/common/http";
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {BubbleModule} from "./migration/signup/bubble.module";
import {TypeformModule} from "./migration/signup/typeform.module";
import {OnDemandModule} from "./features/ondemand/ondemand.module";
import {AppComponent} from './app.component';
import {AuthGuard} from './core/security/auth.guard';
import {NotFoundErrorPage} from "./core/pages/errors/not-found-error.page";
import {LoginPage} from "./core/pages/login/login.page";
import {RecoveryPage} from "./core/pages/recovery/recovery.page";
import {HomePageComponent} from './features/home/ui/home-page/home-page.component';
import {HistoryViewComponent} from './features/history/ui/history/view/history-view.component';
import {MainLayoutComponent} from "./ui/main-layout/main-layout.component";
import {PageHeaderComponent} from "./ui/page-header/page-header.component";
import {PageFooterComponent} from "./ui/page-footer/page-footer.component";
import {MainMenuComponent} from "./ui/main-menu/main-menu.component";
import {HeaderDataService} from "./core/state/header-data.service";
import {EmbeddedPagesModule} from "./migration/embedded/embedded-pages.module";

@NgModule({
    declarations: [
        AppComponent,
        NotFoundErrorPage,
        LoginPage,
        RecoveryPage,
        MainLayoutComponent,
        PageHeaderComponent,
        PageFooterComponent,
        MainMenuComponent,
        HomePageComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        OnDemandModule,
        BubbleModule,
        TypeformModule,
        EmbeddedPagesModule
    ],
    providers: [AuthGuard, HeaderDataService],
    exports: [],
    bootstrap: [AppComponent]
})
export class AppModule { }
