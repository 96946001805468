import { Component, OnInit } from '@angular/core';
import { PublishedVideo } from "../../../model/published-video";
import { PublishedVideoService } from "../../../services/published-video.service";
import { PublishedVideoQuery } from "../../../model/published-video-query";
import { ContentTag } from 'src/app/core/model/content-tag';
import { Location } from "@angular/common";
import { ROUTE_EMBEDDED_LIBRARY, ROUTE_MY_LIBRARY } from "../../../../../core/navigation";
import { ActivatedRoute, Router } from "@angular/router";
import { TrainerProfile } from '../../../model/trainer-profile';
import { ApplicationStateService } from "../../../../../core/state/application-state-service";
import { BehaviorSubject } from "rxjs";
import { Playlist } from "../../../model/playlist";
import { Resources } from "../../../../../core/api/hal/resources";
import { parseTemplate } from 'src/app/core/api/hal/templates';
import { APIClient } from 'src/app/core/api/api-client';
import { BubbleId } from 'src/app/features/history/model/bubble-id';

export type Preset = 'all' | 'fav' | 'user';
export const VIDEO_QUERY = "library_query";
export const REL_FIND_BY_LEGACY_ID = "content:find-by-legacy-id"

@Component({
  selector: 'app-library',
  templateUrl: './library-view.component.html',
  styleUrls: ['./library-view.component.scss']
})

export class LibraryView implements OnInit {
  videos: PublishedVideo[] = [];
  selectedVideo: PublishedVideo | null = null;
  showVideoDetailsPopup: boolean = false;
  showFilterPopup: boolean = false;

  query: BehaviorSubject<PublishedVideoQuery> = new BehaviorSubject<PublishedVideoQuery>(new PublishedVideoQuery());

  tags: ContentTag[] = [];
  trainers: TrainerProfile[] = [];
  presetValue: Preset = 'all'
  favorites: Playlist | undefined = undefined;
  bubbleId: string | null = null;
  currentUrl: string | null = null;

  constructor(
    private content: PublishedVideoService,
    private location: Location,
    private route: ActivatedRoute,
    private router: Router,
    private state: ApplicationStateService,
    private readonly client: APIClient
  ) {
    this.route.queryParams.subscribe(params => {
      let bubbleId = params['bubbleId'];
      this.bubbleId = bubbleId;
    });
  }

  ngOnInit(): void {
    // check if we navigated by direct video link
    let id = this.route.snapshot.paramMap.get("id");
    this.fetchBubbleId()

    if (id === "videos") id = null;

    this.query.subscribe(query => {
      this.updateContentWith(query);
      this.state.currentTab().update(state => {
        state.set(VIDEO_QUERY, query);
        return state;
      });
    });

    if (!id) {
      // video not selected: apply filter and fetch matching videos
      // TODO: parse query from URL
      this.updateContentWith(this.query.getValue());
    } else {
      // video selected: we still fetch all videos and then just display the one with matching ID
      this.updateContentWith(this.query.getValue(), resources => {
        const matching = resources.elements().filter(video => video.id === id);
        if (matching.length == 1) {
          this.selectedVideo = matching[0];
          this.showVideoDetailsPopup = true;
        }
      });
    }
  }

  private fetchBubbleId() {
    if (this.bubbleId) {
      const href = this.state.application().current().expectFullyInitialized().entry?.link(REL_FIND_BY_LEGACY_ID).href;
      if (href) {
        const link = parseTemplate(href).expand("bubbleId", this.bubbleId)
        this.client.get(link, BubbleId).subscribe({
          next: (response: BubbleId) => {
            this.openVideoByBubbleId(response.uuid);
          },
          error: (error) => {
            console.error(error)
          }
        })
      }
    }
  }

  private updateContentWith(query: PublishedVideoQuery, fn?: (content: Resources<PublishedVideo>) => void) {
    this.content.findAll(query).subscribe(resources => {
      this.favorites = resources.favorites();
      var videos = resources.elements();
      this.videos = !videos ? [] : videos;
      this.tags = resources.allTags();
      this.trainers = resources.allTrainers();
      if (!!fn) fn(resources);
    });
  }

  openVideoDetails(video: PublishedVideo): void {
    this.selectedVideo = video;
    this.showVideoDetailsPopup = true;
    const route = this.state.currentTab().current().isEmbedded() ? ROUTE_EMBEDDED_LIBRARY : ROUTE_MY_LIBRARY;
    history.pushState(null, '', `${route}/${video.id}`);
  }

  openVideoByBubbleId(id: string) {
    const route = this.state.currentTab().current().isEmbedded() ? ROUTE_EMBEDDED_LIBRARY : ROUTE_MY_LIBRARY;
    this.router.navigate([route + '/' + id])
  }

  handleClosePopup(): void {
    this.showVideoDetailsPopup = false;
    const route = this.state.currentTab().current().isEmbedded() ? ROUTE_EMBEDDED_LIBRARY : ROUTE_MY_LIBRARY;

    history.pushState(null, '', route);
    this.updateContentWith(this.query.getValue());
  }

  openFilterPopup() {
    this.showFilterPopup = true;
  }

  applyFilter(query: PublishedVideoQuery) {
    this.query.next(query);
    this.showFilterPopup = false;
    this.presetValue = query.isEmpty() ? 'all' : query.isOnlyFavorites() ? 'fav' : 'user';
  }

  closeFilter() {
    this.showFilterPopup = false;
  }

  toggleFilterPreset(value: Preset) {
    this.presetValue = value;
    switch (value) {
      case "fav": {
        this.query.next(this.favorites ? new PublishedVideoQuery([this.favorites.id]) : new PublishedVideoQuery());
        break;
      }
      case "all": {
        this.query.next(new PublishedVideoQuery());
        break;
      }
      default: {
        console.warn("Unexpected preset value: " + value);
      }
    }
  }
}
