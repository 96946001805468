import { Injectable, NgZone } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WindowService {
  private windowWidth = new BehaviorSubject<number>(window.innerWidth);
  windowWidth$ = this.windowWidth.asObservable();

  constructor(private ngZone: NgZone) {
    this.initializeResizeObserver();
  }

  private initializeResizeObserver() {
    const resizeObserver = new ResizeObserver(entries => {
      for (let entry of entries) {
        this.ngZone.run(() => {
          this.windowWidth.next(entry.contentRect.width);
        });
      }
    });

    resizeObserver.observe(document.body);
  }
}
