import {EmbeddedMapping, LookupData, onEmbedded, Resource} from "./resource";
import {REL_ITEMS} from "./hal-link";

class LookupDataHolder implements LookupData {

  private readonly data : { [rel: string] : Resource[] } = {}
  private readonly mappings : { [rel: string] : string };

  constructor(mappings : { [rel: string] : string } = {}) {
    this.mappings = mappings;
  }

  public lookup(rel: string) : Resource[] | undefined {
    const lookupRel = this.mappings[rel] ? this.mappings[rel] : rel;
    return this.data[lookupRel];
  }

  add(lookupRel: string, values: Resource[]): void {
    this.data[lookupRel] = values;
  }
}

export class LookupDataBuilder {

  private readonly mappings : { [rel: string] : string } = {};
  constructor() {}
  public map(rel : string, target : string) : LookupDataBuilder {
    this.mappings[rel] = target;
    return this;
  }

  public build(): LookupDataHolder {
    return new LookupDataHolder(this.mappings);
  }

}

export function onLookup() : LookupDataBuilder {
  return new LookupDataBuilder();
}

export class Resources<T> extends Resource {

  constructor(props: any,
              ctor: new (body: any, lookupData?: LookupData) => T,
              mapping?: EmbeddedMapping,
              lookupData: LookupData = new LookupDataHolder()) {
    super(props, onEmbedded(mapping)
      .execute(REL_ITEMS, body => new ctor(body, lookupData))
      .build(), lookupData);
  }

  public elements(): T[] {
    return this._embedded[REL_ITEMS] as T[];
  }

}
