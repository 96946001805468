import { Component } from '@angular/core';
import { environment } from '../../../environments/environment';
import {AuthenticationService} from "../../core/security/authentication-service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss']
})
export class MainMenuComponent {

  constructor(private auth : AuthenticationService,
              private router : Router) {
  }

  logout() {
    this.auth.logout().subscribe(success => {
      if (success) this.router.navigate(["login"]);
    })
  }
}
