import { Injectable } from '@angular/core';
import { format, parseISO } from 'date-fns';
import { de } from 'date-fns/locale';
import { DateTime, Duration } from 'luxon';
import { LegacySessionStateService } from 'src/app/core/deprecated/legacy-session-state.service';

@Injectable({
  providedIn: 'root'
})
export class FormattingService {

  constructor(public state: LegacySessionStateService) { }

  formatDuration(value: string | null) {
    if (!!value) {
      const duration = Duration.fromISO(value);
      const formatted = duration.toFormat("m s");
      const strMinutes = formatted.split(' ')[0];
      const secs = (+formatted.split(' ')[1] >= 30) ? 1 : 0;
      const roundedMinuts = (+strMinutes + secs).toString()
      return roundedMinuts;
    }
    return null;
  }

  formatSecondsToRoundedMinutes(seconds: number):string {
    return Math.round(seconds / 60).toString();
  }

  formatDurationInSeconds(value: string | undefined) {
    if (!!value) {
      const duration = Duration.fromISO(value);
      const formatted = duration.toFormat("s");
      return formatted;
    }
    return '0';
  }

  formatCustomDate(inputDate: string) {
    const parsedDate = parseISO(inputDate);
    const formattedDate = format(parsedDate, "E, dd.MM.yy", { locale: de });
    return formattedDate;
  }

  formatMonthAndDayDate(inputDate: string) {
    const parsedDate = parseISO(inputDate);
    const formattedDate = format(parsedDate, "dd.MM.yy");
    return formattedDate;
  }

  convertTimeZone(timeStr: string) {
    const backendTime = DateTime.fromISO(timeStr, { zone: 'utc' });
    const customerTimeZone = this.state.get('timeZone:');
    const berlinTime = backendTime.setZone(customerTimeZone);
    const berlinTimeStr = berlinTime.toFormat('yyyy-MM-dd HH:mm:ss ZZZZ');
    return berlinTimeStr;
  }
}
