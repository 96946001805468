<div class="video-card" (click)="openVideoDetails(video)" *ngIf="video">
  <div class="video-card__thumbnail-wrapper">
    <img class="video-card__thumbnail" src="{{video.thumbnailImage()}}" alt="">

    <div class="video-card__duration">
      <p>{{video.formattedDuration()}}</p>
    </div>
  </div>

  <svg *ngIf="canToggleFavorite()" (click)="toggleFavorite($event)" class="video-card__heart" width="26" height="23" viewBox="0 0 26 23" fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.6497 2.3161C23.7119 4.37829 23.7909 7.69659 21.8291 9.85453L11.9996 20.6664L2.17083 9.85453C0.209065 7.69658 0.288057 4.37832 2.35025 2.31613C4.6528 0.0135713 8.44495 0.223706 10.4792 2.76646L12 4.66656L13.5195 2.76639C15.5537 0.223636 19.3472 0.0135399 21.6497 2.3161Z"
      [ngClass]="isFavorite ? 'video-card__heart--favorite' : 'video-card__heart--not-favorite'" />
  </svg>

  <div *ngIf="video.isRecommended()" class="video-card__recommended recommended">
    <p>Empfehlung</p>
  </div>

  <div class="video-card__info">
    <p>{{video.name}}</p>

    <div class="video-card__line">
      <p>{{video.findTrainer().name.given}}</p>
      <p>Level {{video.level(tags)?.name}}</p>
    </div>
  </div>
</div>
