export type SalutationForm = 'MALE' | 'FEMALE' | 'DIVERSE' | 'UNKNOWN';

export class PersonalName {

  public readonly given: string;
  public readonly family: string;
  public readonly full: string;
  public readonly salutation: SalutationForm;

  constructor(json : any) {
    if (!json) throw new Error('Personal name cannot be empty');
    this.given = json['given'];
    this.family = json['family'];
    this.full = json['full'];
    this.salutation = json['salutation'];
  }
}
