import { NgModule } from '@angular/core';
import { HttpClientModule } from "@angular/common/http";
import { BrowserModule } from '@angular/platform-browser';


import {RedirectToTypeformComponent} from "./ui/redirect/redirect-to-typeform.component";
import { EmailcheckComponent } from './ui/emailcheck/emailcheck.component';

@NgModule({
  declarations: [
    RedirectToTypeformComponent,
    EmailcheckComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule
  ],
  providers: [],
  bootstrap: [RedirectToTypeformComponent]
})
export class TypeformModule { }
