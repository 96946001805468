import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LegacySessionStateService {

  constructor() { }

  set(key: string, value: any) {
    sessionStorage.setItem(key, value);
  }

  get(key: string): any | null {
    const value = sessionStorage.getItem(key);
    return !!value ? value : null;
  }

  remove(key: string){
    sessionStorage.removeItem(key);
  }
}
