import { Component } from '@angular/core';
import {APIClient} from "./core/api/api-client";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {

  constructor(private apiClient : APIClient) {
  }

  ngOnInit() {
    this.apiClient.initialize().subscribe({
      next: (newState: any) => {
        console.log('App successfully initialized');
      },
      error: (error: any) => {
        console.error('Failed to initializing app', error);
      }
    });
  }

}
