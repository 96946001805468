import {Resource} from "../api/hal/resource";
import {PersonalName} from "./personal-name";
import {Contact} from "./contact";
import {Address} from "./address";
import {Duration} from "luxon";

export class SubscriptionTerms {
  public readonly product : string;
  public readonly start : Date;
  public readonly end : Date;
  public readonly duration : Duration;

  constructor(json : any) {
    this.product = json["product"] as string;
    this.start = new Date(json["start"]);
    this.end = new Date(json["end"]);
    this.end.setHours(23, 59);
    this.duration = Duration.fromISO(json["duration"]);
  }

  public isTrial() : boolean {
    return this.product === "trial";
  }

  public isPaid() : boolean  {
    return this.product !== "free";
  }

  remainingDays() : number {
    return Math.round(Math.abs((+this.end - (+new Date())) / 8.64e7));
  }

  level() {
    return this.product === "trial" ? "ACTIVE" : this.product.toUpperCase();
  }
}

export class CustomerSubscription {
  public readonly uuid: string;
  public readonly terms : SubscriptionTerms;
  public readonly autoRenewed: boolean;
  public readonly type : string;
  constructor(json: any) {
    this.uuid = json["uuid"];
    this.terms = new SubscriptionTerms(json["terms"]);
    this.autoRenewed = json["autoRenewed"] as boolean;
    this.type = json["type"] as string;
  }
}

export class Customer extends Resource {

  public readonly uuid: string;
  public readonly name : PersonalName;
  public readonly birthDate : string | undefined;
  public readonly locale : string | undefined;
  public readonly timezone : string | undefined;
  public readonly email : Contact;
  public readonly phone : Contact | undefined;
  public readonly address : Address | undefined;
  public readonly trackingConsent : boolean;
  public readonly marketingConsent : boolean;
  public readonly subscription : CustomerSubscription | undefined;

  constructor(props: any) {
    super(props);
    this.uuid = props["uuid"] as string;
    this.name = Resource.parseRequired(props, "name", PersonalName);
    this.birthDate = props["birthDate"] as string | undefined;
    this.locale = props["locale"] as string | undefined;
    this.timezone = props["timezone"] as string | undefined;
    this.email = Resource.parseRequired(props, "email", Contact);
    this.phone = Resource.parseOptional(props, "phone", Contact);
    this.address = Resource.parseOptional(props, "address", Address);
    this.trackingConsent = !!props["trackingConsent"];
    this.marketingConsent = !!props["marketingConsent"];
    this.subscription = Resource.parseOptional(props, "subscription", CustomerSubscription);
  }

}
