
export type URITemplateParam = string | string[] | number | number[] | boolean | null;

export interface URITemplateValue {
  expandAll(values : { [key : string] : URITemplateParam | undefined }) : string;
}

export class URITemplatePart implements URITemplateValue {
  constructor(public readonly value : string) {
  }
  expandAll(values: { [p: string]: URITemplateParam | undefined }): string {
    return this.value;
  }
}

type URITemplateOperator = (key : string, value : URITemplateParam, first : boolean) => string;

const OPERATORS : { [operator : string] : URITemplateOperator } = {
  '' : function (key : string, value : URITemplateParam) : string {
    return (Array.isArray(value) ? value : [ value ]).join();
  },
  '/' : function (key : string, value : URITemplateParam) : string {
    return '/' + (Array.isArray(value) ? value : [ value ]).join('/');
  },
  '?' : function (key : string, value : URITemplateParam, first : boolean) : string {
    return (first ? '?' : '&') + key + '=' + (Array.isArray(value) ? value : [ value ]).join('&' + key + '=');
  },
  '&' : function (key : string, value : URITemplateParam) : string {
    return '&' + key + '=' + (Array.isArray(value) ? value : [ value ]).join('&' + key + '=');
  }
}

export class URITemplateComponent {
  constructor(public readonly key : string,
              public readonly length : number | undefined,
              public readonly explode : boolean | undefined) {
  }
  expand(operator : string, value : URITemplateParam, first : boolean) : string {
    return OPERATORS[operator](this.key, value, first);
  }
  toString(operator : string) : string {
    return '{' + (!!operator ? operator : '') + this.key + (!!this.length ? ':' + this.length : '') + (!!this.explode? '*' : '') + '}';
  }

}

export class URITemplateVariable implements URITemplateValue {

  constructor(public readonly operator : string,
              public readonly components : URITemplateComponent[]) {
  }

  public expandAll(values : { [key : string] : URITemplateParam | undefined }) {
    let result = '';
    let first = true;
    for (let component of this.components) {
      const value : URITemplateParam | undefined = values[component.key];
      result += !!value ? component.expand(this.operator, value, first) : ""; // component.toString(this.operator);
      first =  first && (value === undefined);
    }
    return result;
  }
}

export class URITemplate {

  constructor(private readonly elements : URITemplateValue[]) {
  }

  public raw() : URITemplateValue[] {
    return this.elements;
  }

  public expand(key : string, value : string) : string {
    const model : { [k : string]  : string } = {};
    model[key] = value;
    return this.expandAll(model);
  }

  public expandAll(values : { [key : string] : URITemplateParam | undefined }) : string {
    let result = '';
    for (let element of this.elements) {
      result += element.expandAll(values);
    }
    return result;
  }

  public toString() : string {
    return this.expandAll({});
  }
}

export function parseTemplate(href : string) : URITemplate {
  let values : URITemplateValue[] = [];
  let buf = '';
  let template = false;
  for (let i = 0; i < href.length; i++) {
    let c = href.charAt(i);
    switch (c) {
      case '{' : {
        if (buf.length > 0) {
          values.push(new URITemplatePart(buf));
        }
        buf = '{';
        template = true;
        break;
      }
      case '}' : {
        if (buf.length < 3) {
          template = false;
        }
        if (template) {
          let operator = buf.charAt(1);
          let offset = 2;
          if (!OPERATORS.hasOwnProperty(operator)) {
            operator = '';
            offset = 1;
          }
          let keys = buf.slice(offset);
          let comps = keys.split(',').map(key => new URITemplateComponent(key, undefined, undefined));
          values.push(new URITemplateVariable(operator, comps));
          buf = '';
          template = false;
        } else {
          buf += '}';
        }
        break;
      }
      default:
        buf += c;
    }
  }
  if (buf.length > 0) values.push(new URITemplatePart(buf));
  return new URITemplate(values);
}
