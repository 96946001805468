import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PublishedVideo } from "../../../model/published-video";
import { PublishedVideoService } from '../../../services/published-video.service';
import { ContentTag } from 'src/app/core/model/content-tag';
import { ActivatedRoute, Router } from '@angular/router';
import { PublishedVideoQuery } from '../../../model/published-video-query';
import { TrainerProfile } from '../../../model/trainer-profile';
import { ROUTE_EMBEDDED_LIBRARY, ROUTE_MY_LIBRARY } from 'src/app/core/navigation';
import { ApplicationStateService } from "../../../../../core/state/application-state-service";

@Component({
  selector: 'app-video-details-popup',
  templateUrl: './video-details-popup.component.html',
  styleUrls: ['./video-details-popup.component.scss']
})
export class VideoDetailsPopupComponent implements OnInit {
  query: PublishedVideoQuery = new PublishedVideoQuery();
  video: PublishedVideo | undefined = undefined;
  videos: PublishedVideo[] = [];
  trainers: TrainerProfile[] = [];
  tags: ContentTag[] = [];
  videoHeight: number = 300;

  @Input() selectedVideo: PublishedVideo | null | undefined;
  @Output() closeVideo = new EventEmitter<void>();

  @Output() closePopup = new EventEmitter<void>();

  constructor(
    private videoService: PublishedVideoService,
    private route: ActivatedRoute,
    private router: Router,
    private state: ApplicationStateService
  ) { }

  ngOnInit(): void {

    if (window.innerWidth > 600) {
      this.videoHeight = 400;
    }

    if (window.innerWidth > 1200) {
      this.videoHeight = window.innerWidth / 2.5;
    }
    let id;

    if (!!this.selectedVideo) {
      id = this.selectedVideo.id;
    } else id = this.route.snapshot.paramMap.get('id');

    if (!!id) {
      if (id === "videos") {
        console.log("Ignoring request to open popup: id=videos");
        return;
      }
      this.videoService.get(id).subscribe(res => {
        this.video = res;
        this.videoService.findAll(this.query).subscribe(resources => {
          this.videos = resources.elements();
          this.tags = resources.allTags();
          this.trainers = resources.allTrainers();
        });
      })
    }
  }

  onGrandchildClose(): void {

    const route = this.state.currentTab().current().isEmbedded() ? ROUTE_EMBEDDED_LIBRARY : ROUTE_MY_LIBRARY;

    if (this.selectedVideo) {
      this.closePopup.emit();

      history.pushState(null, '', route)
    } else {
      this.router.navigate([route]);
    }
  }

  canToggleFavorite(): boolean {
    return !!this.video && this.videoService.canToggleFavorite(this.video);
  }

  toggleFavorite(): void {
    if (!!this.video) {
      this.videoService.toggleFavorite(this.video).subscribe(update => {
        this.video = update;
      });
    }
  }
}
