import {parseTemplate} from "./templates";
import {Predicate} from "./util";

export const REL_SELF = "self";
export const REL_NEXT = "next";
export const REL_PREV = "prev";
export const REL_PAGE = "page";
export const REL_ITEMS = "items";
export const PARAM_EXPAND = "expand";


export class HALLink {
  readonly href: string;
  readonly templated: boolean | undefined;
  readonly title: string | undefined;
  readonly name: string | undefined;
  readonly hreflang: string | undefined;
  readonly type: string | undefined;
  readonly profile: string | undefined;
  readonly deprecation: string | undefined;

  constructor(props: any) {
    this.href = props['href'] as string;
    this.templated = props['template'] as boolean;
    this.title = props['title'] as string;
    this.name = props['name'] as string;
    this.hreflang = props['hreflang'] as string;
    this.type = props['type'] as string;
    this.profile = props['profile'] as string;
    this.deprecation = props['deprecation'] as string;
  }

  public is(test : Predicate<HALLink>): boolean {
    return test(this);
  }

  public expand(key : string, value : string): string {
    if (this.templated) {
      return parseTemplate(this.href).expand(key, value);
    } else {
      return this.href;
    }
  }

  public expandAll(props : { [key : string] : string | string[] }): string {
    if (this.templated) {
      return parseTemplate(this.href).expandAll(props);
    } else {
      return this.href;
    }
  }
}

/**
 * Predicates
 */
export const HREF_SAME_RESOURCE = ".";
export const SAME_RESOURCE   : Predicate<HALLink> = (link : HALLink) => HREF_SAME_RESOURCE === link.href;
export const LINK_DEPRECATED : Predicate<HALLink> = (link : HALLink) => !!link.deprecation;

