import { StateStorage } from "./state-storage";
import { ApplicationState } from "./appilication-state";
import { Injectable } from "@angular/core";
import { SessionState } from "./session-state";

@Injectable({
  providedIn: 'root'
})
export class ApplicationStateService {
  private applicationState: StateStorage<ApplicationState>;
  private sessionState: StateStorage<SessionState>;

  constructor() {
    this.applicationState = new StateStorage<ApplicationState>(localStorage, ApplicationState.fromJson);
    this.sessionState = new StateStorage<SessionState>(sessionStorage, SessionState.fromJson);
    this.applicationState.init();
  }

  /**
   * Reset application state (e.g. on logout)
   */
  clear(): void {
    this.applicationState.clear();
    this.sessionState.clear();
  }

  /**
   * Returns state relevant only to the current tab (page-specific data)
   */
  public currentTab(): StateStorage<SessionState> {
    return this.sessionState;
  }

  /**
   * Returns state relevant to
   */
  public application(): StateStorage<ApplicationState> {
    return this.applicationState;
  }
}
