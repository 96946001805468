import {Resource} from "../../../core/api/hal/resource";
import {Duration} from "luxon";

export type SessionType = "LIVE" | "ONDEMAND";

export class Participation extends Resource {

  private readonly id : string;
  private readonly participantId : string;
  private readonly contentId : string;
  private readonly type : SessionType;
  private readonly start: string;
  private readonly duration: Duration;

  constructor(props : any) {
    super(props);
    this.id = props["id"] as string;
    this.participantId = props["participantId"] as string;
    this.contentId = props["contentId"] as string;
    this.type = props["type"] as SessionType;
    this.start = props["start"] as string;
    this.duration = Duration.fromISO(props["duration"] as string);
  }


}
