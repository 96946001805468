import {Component} from "@angular/core";
import {Router} from "@angular/router";
import {ApplicationStateService} from "../../state/application-state-service";
import {AuthenticationService} from "../../security/authentication-service";
import {Observable} from "rxjs";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ROUTE_MY_HOME, ROUTE_RECOVER_PASSWORD} from "../../navigation";

@Component({
  selector: 'login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss']
})
export class LoginPage {

  public loginForm !: FormGroup;
  public showError: boolean = false;
  public emailValue: string = "";

  constructor(private router : Router,
              private state : ApplicationStateService,
              private authenticator : AuthenticationService,
              private formBuilder: FormBuilder) {
    this.loginForm = this.formBuilder.group({
    username: ['', [Validators.required, Validators.email]],  // Using email validation for username
    password: ['', Validators.required]
  });
}

  ngOnInit(): void {
    const isAuthenticated = this.authenticator.isAuthenticated();
    if (isAuthenticated instanceof Observable) {
      isAuthenticated.subscribe(success => {
        this.router.navigate([ "/" + ROUTE_MY_HOME ])
      })
    } else if (isAuthenticated) {
      this.router.navigate([ "/" + ROUTE_MY_HOME ]);
    }
  }

  submitLogin() {
    if (this.loginForm.invalid) {
      alert('Bitte füllen Sie alle Felder korrekt aus.');
      return;
    }

    // Handle valid form submission (authentication logic goes here)
    const username = this.loginForm.get('username')?.value;
    const password = this.loginForm.get('password')?.value;
    this.authenticator.login(username, password).subscribe({
      next: (success) => {
        if (success) {
          this.router.navigate([ ROUTE_MY_HOME ])
        } else {
          this.showError = true;
        }
      },
      error: (error) => { console.log(error); this.showError = true }
    })
  }

  // Helper methods for form controls
  get username() {
    return this.loginForm.get('username');
  }

  get password() {
    return this.loginForm.get('password');
  }

  resetPassword() {
    this.router.navigate([ ROUTE_RECOVER_PASSWORD ], { state: { email: this.emailValue } })
  }

}
