import {Component} from "@angular/core";

@Component({
  selector: 'not-found-error',
  templateUrl: './not-found-error.page.html',
  styleUrls: ['./not-found-error.page.scss']
})
export class NotFoundErrorPage {

}
