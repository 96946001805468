import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomePage} from './features/home/ui/home-page/home-page.component';
import {LoginPage} from "./core/pages/login/login.page";
import {SetupPage} from "./core/pages/setup/setup.page";
import {RecoveryPage} from "./core/pages/recovery/recovery.page";
import {NotFoundErrorPage} from "./core/pages/errors/not-found-error.page";
import {MainLayoutComponent} from "./ui/main-layout/main-layout.component";
import {LibraryPage} from "./features/ondemand/ui/library/library.page";
import {AuthGuard} from "./core/security/auth.guard";
import {HistoryPage} from "./features/history/ui/history/history.page";
import { VideoDetailsPopupComponent } from "./features/ondemand/ui/library/video-details-popup/video-details-popup.component";

const routes: Routes = [

  // Pre-login
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'not-found', component : NotFoundErrorPage },
  { path: 'login', component : LoginPage },
  { path: 'setup', canActivate : [AuthGuard], component : SetupPage },
  { path: 'recovery', component : RecoveryPage },
  { path : 'my/profile', redirectTo: 'setup' },
  {
    path: '',
    component : MainLayoutComponent,
    canActivate: [AuthGuard],
    children : [
      { path: 'home', component: HomePage },
      { path: 'library/videos', component: LibraryPage },
      { path: 'library/videos/:id', component: VideoDetailsPopupComponent },
      { path: 'history', component: HistoryPage },
      { path : '**', redirectTo : "not-found" }
    ]
  },
  { path: '**', redirectTo: 'not-found' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
