import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { mergeMap, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AttendanceService } from '../../services/attendance.service';
import { PublishedVideoService } from '../../services/published-video.service';
import { PublishedVideo } from "../../model/published-video";

/**
 * Legacy component for integration with Bubble: displays video player in an iframe,
 * accepting contentId (video external ID) as a URL query parameter.
 */
@Component({
  selector: 'app-video-page',
  templateUrl: './video.page.html',
  styleUrls: ['./video.page.scss'],
})

export class VideoPage implements OnInit {

  video: PublishedVideo | undefined;

  width: number | undefined;
  height: number | undefined;

  constructor(
    private route: ActivatedRoute,
    private attendance: AttendanceService,
    private router: Router,
    private videos: PublishedVideoService,
  ) { }

  ngOnInit(): void {
    this.width = window.innerWidth * 0.99;
    this.height = window.innerHeight * 0.99;

    this.route.queryParams.pipe(mergeMap(params => {
      const contentId = params['content_id'];

      if (contentId) {
        return this.videos.findByExternalId(contentId.split('?'));
      } else {
        return of(undefined);
      }
    })).subscribe(video => {
      this.video = video;
      if (!video) {
        environment.loggingEnabled && console.log('Video not found or not supported and will not be displayed');
      }
    });
  }

}
