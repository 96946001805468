import {Resource} from "../../../core/api/hal/resource";
import {HistoryItem} from "./historyTypes";

export class HistoryElement extends Resource implements HistoryItem {
  public readonly uuid: string;
  public readonly contentId: string;
  public readonly duration: string;
  public readonly participantId: string;
  public readonly start: string;
  public readonly type: "ONDEMAND" | "LIVE";

  public constructor(json : any) {
    super(json);
    this.uuid = json["uuid"] as string;
    this.contentId = json["contentId"] as string;
    this.duration = json["duration"] as string;
    this.participantId = json["participantId"] as string;
    this.start = json["start"] as string;
    this.type = json["type"];
  }

}
