import { Resource } from "../../../core/api/hal/resource";
import { PersonalName } from "../../../core/model/personal-name";

export class TrainerProfile extends Resource {

  public readonly id: string;
  public readonly name: PersonalName;
  public readonly introText: string;
  public readonly experienceSince: number;

  private readonly photo: string;
  private readonly introVideo: string;

  constructor(props: any) {
    super(props);
    this.id = props["id"] as string;
    this.name = Resource.parseRequired(props, "name", PersonalName);
    this.introText = props["introText"] as string;
    this.experienceSince = props["experienceSince"] as number;
    this.photo = props["photo"] as string;
    this.introVideo = props["introVideo"] as string;
  }

  public photoLink(): string {
    return this.photo;
  }

  public videoLink(): string {
    return this.introVideo;
  }
}
