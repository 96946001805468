import { Component } from '@angular/core';
import {RouterOutlet} from "@angular/router";
import {ApplicationStateService} from "../../../core/state/application-state-service";

@Component({
  selector: 'app-embedded-page-layout',
  standalone: true,
  imports: [
    RouterOutlet
  ],
  templateUrl: './embedded-page-layout.component.html',
  styleUrls:[ './embedded-page-layout.component.scss']
})
export class EmbeddedPageLayoutComponent {

  constructor(state : ApplicationStateService) {
    state.currentTab().update(current => current.asEmbedded());
  }

}
