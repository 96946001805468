<div class="container">
  <div class="logo">
    <img src="assets/images/logo.png" alt="150 Minuten | Firmenlogo">
  </div>
  <h1>Login</h1>
  <p>Bitte melden Sie sich mit Ihren Zugangsdaten an.</p>

  <form [formGroup]="loginForm" (ngSubmit)="submitLogin()">
    <div class="form-group">
      <label for="username">E-Mail:</label>
      <input type="email" id="username" formControlName="username">
      <!-- Validation Error for Username -->
      <div *ngIf="username?.invalid && (username?.dirty || username?.touched)" class="error-message">
        <small *ngIf="username?.errors?.['required']">E-Mail ist erforderlich.</small>
        <small *ngIf="username?.errors?.['email']">Bitte geben Sie eine gültige E-Mail-Adresse ein.</small>
      </div>
    </div>

    <div class="form-group">
      <label for="password">Passwort:</label>
      <input type="password" id="password" formControlName="password">
      <!-- Validation Error for Password -->
      <div *ngIf="password?.invalid && (password?.dirty || password?.touched)" class="error-message">
        <small *ngIf="password?.errors?.['required']">Passwort ist erforderlich.</small>
      </div>
    </div>

    <button type="submit" class="submit-btn" [disabled]="loginForm.invalid">Einloggen</button>
    <a class="action" (click)="resetPassword()">Passwort vergessen? Mit Link anmelden</a>
  </form>

  <div class="footer">
    <a href="https://150minuten.de/impressum" target="_blank">Impressum</a>
    <a href="https://150minuten.de/agb" target="_blank">AGB</a>
    <a href="https://150minuten.de/datenschutz" target="_blank">Datenschutz</a>
  </div>

</div>
