import {Resource} from "../api/hal/resource";
import {Customer} from "./customer";

export class AuthenticationDetails {

  public readonly type : string;
  public readonly represented_by ?: string;
  public readonly principal : Customer;

  constructor(json : any) {
    if (!json) throw new Error('Authentication details cannot be undefined or null');
    this.type = json['type'];
    this.represented_by = json['represented_by'];
    this.principal = Resource.parseRequired(json, 'principal', Customer);
  }

  public isImpersonated() : boolean {
    return !!this.represented_by;
  }

}
