import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomePageComponent} from './features/home/ui/home-page/home-page.component';
import {RedirectToBubbleComponent} from "./migration/signup/ui/redirect-to-bubble/redirect-to-bubble.component";
import {RedirectToTypeformComponent} from "./migration/signup/ui/redirect/redirect-to-typeform.component";
import {EmailcheckComponent} from './migration/signup/ui/emailcheck/emailcheck.component';
import {LoginPage} from "./core/pages/login/login.page";
import {RecoveryPage} from "./core/pages/recovery/recovery.page";
import {NotFoundErrorPage} from "./core/pages/errors/not-found-error.page";
import {EMBEDDED_ROUTES, EmbeddedRoutesModule} from "./migration/embedded/embedded-routes.module";
import {EmbeddedPageLayoutComponent} from "./migration/embedded/layout/embedded-page-layout.component";
import {MainLayoutComponent} from "./ui/main-layout/main-layout.component";
import {LibraryPage} from "./features/ondemand/ui/library/library.page";
import {AuthGuard} from "./core/security/auth.guard";
import {HistoryPage} from "./features/history/ui/history/history.page";
import {
  VideoDetailsPopupComponent
} from "./features/ondemand/ui/library/video-details-popup/video-details-popup.component";

const routes: Routes = [
  // MIGRATION v1->v2
  // Registration process
  { path: 'redirect', component: RedirectToBubbleComponent },
  { path: 'register', component: RedirectToTypeformComponent },
  { path: 'emailcheck', component: EmailcheckComponent },

  // APP v2
  // Pre-login
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'not-found', component : NotFoundErrorPage },
  { path: 'login', component : LoginPage },
  { path: 'recovery', component : RecoveryPage },

  // Post-login
  // all embedded pages
  { path: 'embedded',
    component: EmbeddedPageLayoutComponent,
    canActivate: [AuthGuard],
    children : EMBEDDED_ROUTES
  },

  {
    path: '',
    component : MainLayoutComponent,
    canActivate: [AuthGuard],
    children : [
      { path: 'home', component: HomePageComponent },
      { path: 'library/videos', component: LibraryPage },
      { path: 'library/videos/:id', component: VideoDetailsPopupComponent },
      { path: 'history', component: HistoryPage },
      { path : '**', redirectTo : "not-found" }
    ]
  },
  { path: '**', redirectTo: 'not-found' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
    EmbeddedRoutesModule
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
