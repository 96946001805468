<div class="video-player">
  <div *ngIf="!!video">
    <div id="vimeo-player">
    </div>
  </div>

  <div *ngIf="!video">
    <p>Dieses Video ist nicht verfügbar oder existiert nicht.</p>
  </div>
</div>
