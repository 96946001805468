import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, UrlTree} from '@angular/router';
import {mergeMap, Observable, of} from 'rxjs';
import {AuthenticationService} from "./authentication-service";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard {

  constructor(private authService : AuthenticationService) { }
  canActivate(next: ActivatedRouteSnapshot):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const token = next.queryParamMap.get('authorization_token');
    if (!!token) {
      console.debug("Authorization token detected: " + token);
      return this.authService.clearState().pipe(mergeMap(success => success ? this.authService.refresh(token) : of(false)));
    } else {
      return this.authService.isAuthenticated();
    }
  }
}
