<div class="nav-container">
  <div class="logo">
    <img src="/assets/images/logo.svg" width="130" height="45" alt="150 Minuten logo" />
  </div>

  <menu>
    <li>
      <img
        src="https://6da447865b242be50fa24bd665f88881.cdn.bubble.io/f1719572443044x339912062368940860/Icon%20%284%29.svg">
      <a [routerLink]="['/library/videos']" routerLinkActive="active">Mediathek-Trainings</a>
    </li>

    <li>
      <img
        src="https://6da447865b242be50fa24bd665f88881.cdn.bubble.io/f1719572297965x125277759700754380/Icon%20%281%29.svg">
      <a [routerLink]="['/history']" routerLinkActive="active">Traininghistorie</a>
    </li>
  </menu>

  <ul class="system-links">
    <li><a (click)="logout()">Abmelden</a></li>
    <li>
      <a href="https://150minuten.de" target="_blank">Über 150Minuten</a>
    </li>
  </ul>
</div>
