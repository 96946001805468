export type AttendanceUpdateType = "CONTINUE" | "PAUSE" | "COMPLETE" | "CLOSE";

export class AttendanceUpdate {

  public readonly event_type: AttendanceUpdateType;
  public readonly viewId : string;
  public readonly totalSeconds: number;

  constructor(event_type: AttendanceUpdateType,
              viewId: string,
              totalSeconds: number) {
    this.event_type = event_type;
    this.viewId = viewId;
    this.totalSeconds = totalSeconds;
  }

}
