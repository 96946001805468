import {Predicate} from "./util";

export type HALCollection<T> = { readonly [rel: string]: T[]; }

export function mapCollection<T>(props: any, asInstanceOfT: (object : any, key: string) => T | undefined) : HALCollection<T> {
  const result : { [rel: string]: T[] } = {};
  for (const key in props) {
    if (props.hasOwnProperty(key)) {
      const item = props[key];
      const array = [];
      if (Array.isArray(item)) {
        if (item.length == 0) {
          console.log("Empty value with rel= " + key + "found. Ignoring...");
          continue;
        }
        for (const element of item) {
          try {
            const mapped = asInstanceOfT(element, key);
            if (!!mapped) array.push(mapped);
          } catch (error) {
            console.log("Could not parse rel=" + key + ": " + error);
          }
        }
      } else {
        const mapped = asInstanceOfT(item, key);
        if (!!mapped) array.push(mapped);
      }
      result[key] = array;
    }
  }
  return result;
}
