<div class="wrapper">
  <div class="container">

    <div class="logo">
      <img
        src="https://images.ctfassets.net/et2zhvrxomut/4PtVB5tq1KR3EaBpb8NXu6/ae59553442ac2ddc2b25bd20bbf7c117/150minuten-logo-image.png"
        alt="150Minten Logo">
    </div>

    <div class="content">
      <h1>Wir bereiten jetzt Dein Konto bei 150Minuten vor.</h1>
      <p>Es dauert nur wenige Sekunden. Im nächsten Schritt musst Du nur noch Dein Passwort festlegen. Dann kannst Du
        mit
        150Minuten lostrainieren!</p>
    </div>

    <div>
    </div>

  </div>
</div>
