import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PublishedVideo } from "../../../model/published-video";
import { ContentTag } from 'src/app/core/model/content-tag';
import { PublishedVideoService } from '../../../services/published-video.service';

@Component({
  selector: 'app-video-card',
  templateUrl: './video-card.component.html',
  styleUrls: ['./video-card.component.scss']
})
export class VideoCardComponent implements OnInit {

  @Input() video: PublishedVideo | undefined = undefined;
  @Input() tags: ContentTag[] = [];

  @Output() videoClicked = new EventEmitter<PublishedVideo>();

  isFavorite: boolean | undefined;

  constructor(
    private videoService: PublishedVideoService
  ) { }
  ngOnInit(): void {
    this.isFavorite = this.video?.isFavorite()
  }

  openVideoDetails(video: PublishedVideo | undefined) {
    this.videoClicked.emit(this.video);
  }

  canToggleFavorite(): boolean {
    return !!this.video && this.videoService.canToggleFavorite(this.video);
  }

  toggleFavorite(event: Event): void {
    event.stopPropagation()

    if (!!this.video) {
      this.videoService.toggleFavorite(this.video).subscribe(() => {
        this.isFavorite = !this.video?.isFavorite()
      });
    }
  }
}
