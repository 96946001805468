import { Component, effect, Input, OnDestroy, OnInit } from "@angular/core";
import { PublishedVideo } from "../../model/published-video";
import { AttendanceService } from '../../services/attendance.service';
import { v4 as uuidv4 } from "uuid";
import { environment } from "../../../../../environments/environment";
import { ApplicationStateService } from "../../../../core/state/application-state-service";
import Player from "@vimeo/player";

@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss'],
})

export class VideoPlayerComponent implements OnInit, OnDestroy {

  @Input() video!: PublishedVideo;
  @Input() width?: number;
  @Input() height?: number;
  player?: Player;

  viewedTimes: number[] = [];
  videoEvents: string[] = [];
  startTime: number = 0;
  intervalId: any;
  elapsedTime: number = 0;
  isPlaying: boolean = false;
  totalViewedTime: number = 0;
  staleInterval: any;
  videoElement!: any;

  constructor(private attendance: AttendanceService,
    private state: ApplicationStateService) {
    effect(() => {
      if (!!this.video) {
        this.initializePlayer();
      }
    })
  }

  ngOnInit(): void {
    window.addEventListener('beforeunload', (event) => {
      if (this.isPlaying) {
        const endTime = Date.now();
        const viewedTime = (endTime - this.startTime) / 1000;
        this.viewedTimes.push(viewedTime);
        this.updateTotalViewedTime();
        const viewId = this.getViewId();
        viewId && this.attendance.sendPlaybackDuration(this.video, this.totalViewedTime, 'CLOSE', viewId);
      }
    });
  }

  initializePlayer(): void {
    const options = {
      url: this.video?.video(),
      byline: false,
      pip: false,
      portrait: false,
      title: false,
      vimeo_logo: false,
      width: this.width,
      height: this.height,
    }
    this.player = new Player('vimeo-player', options);

    this.player.on('play', () => {
      this.videoEvents.push('play')
      if (this.videoEvents.length === 1) {
        this.clearViewId();
      }
      let viewId = this.getViewId();
      if (!viewId) {
        viewId = uuidv4();
        this.state.currentTab().update(state => state.set("view_id", viewId));
      }
      environment.loggingEnabled && console.log('Video play. Current viewed time:', this.totalViewedTime, this.viewedTimes);

      this.isPlaying = true;
      this.startTime = Date.now();
    });

    this.player.on('pause', () => {
      this.isPlaying = false;
      const endTime = Date.now();
      const viewedTime = Math.round((endTime - this.startTime) / 1000);
      (this.videoEvents[this.videoEvents.length - 1] !== 'ended') && this.viewedTimes.push(viewedTime);
      this.videoEvents.push('pause')
      this.updateTotalViewedTime();
      const viewId = this.getViewId();
      if (viewId) {
        (this.videoEvents[this.videoEvents.length - 1] !== 'seeked') && this.attendance.sendPlaybackDuration(this.video, this.totalViewedTime, 'PAUSE', viewId);
      }

      environment.loggingEnabled && console.log('Video paused. Current viewed time:', this.totalViewedTime, this.viewedTimes);

      let startPauseTime = 0;
      this.staleInterval = setInterval(() => {
        if (!this.isPlaying && this.totalViewedTime > 0) {
          startPauseTime++;
          if (startPauseTime > environment.stale_view_session) {
            startPauseTime = 0;
            clearInterval(this.staleInterval);
            environment.loggingEnabled && console.log(`${environment.stale_view_session} seconds have passed since the video paused`)
            environment.loggingEnabled && console.log('Session complete after too long pause. Total viewed time:', this.totalViewedTime, 's');
            const viewId = this.getViewId();
            viewId && this.attendance.sendPlaybackDuration(this.video, this.totalViewedTime, 'COMPLETE', viewId);
            this.clearViewId();
            this.viewedTimes = [];
            this.elapsedTime = 0;
          }
        }
      }, 1000);
    });

    this.player.on('seeked', () => {
      this.videoEvents.push('seeked')
      environment.loggingEnabled && console.log('Video seeked');
      if (this.isPlaying) {
        const currentTime = Date.now();
        const viewedTime = (currentTime - this.startTime) / 1000;
        this.viewedTimes.push(viewedTime);
        this.updateTotalViewedTime();
        this.startTime = currentTime;
      }
    });

    this.player.on('ended', () => {
      this.videoEvents.push('ended')
      this.isPlaying = false;
      clearInterval(this.staleInterval);
      environment.loggingEnabled && console.log('Video ended. Total viewed time:', this.totalViewedTime, this.viewedTimes);
      const viewId = this.getViewId();
      viewId && this.attendance.sendPlaybackDuration(this.video, this.totalViewedTime, 'COMPLETE', viewId);
    });

    this.player.on('bufferstart', () => {
      this.isPlaying = false;
      environment.loggingEnabled && console.log('Video buffering started');
    });

    this.player.on('bufferend', () => {
      this.isPlaying = true;
      environment.loggingEnabled && console.log('Video buffering ended');
    });

    this.intervalId = setInterval(() => {

      if (this.isPlaying) {
        this.elapsedTime += 5;
        const viewId = this.getViewId();
        environment.loggingEnabled && console.log('Sended after 5 seconds', this.totalViewedTime, this.viewedTimes);
        viewId && this.attendance.sendPlaybackDuration(this.video, this.elapsedTime, 'CONTINUE', viewId);
      }
    }, 5000);
  }

  private getViewId() {
    return this.state.currentTab().current().get("view_id") as string;
  }

  private clearViewId() {
    this.state.currentTab().update(state => state.set("view_id", undefined));
  }

  ngOnDestroy(): void {
    clearInterval(this.intervalId);
    this.clearViewId();
  }

  updateTotalViewedTime(): void {
    this.totalViewedTime = Math.round(this.viewedTimes.reduce((total, time) => total + time, 0))
  }
}
