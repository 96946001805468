import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {AttendanceUpdate, AttendanceUpdateType} from "../model/attendance-update";
import {PublishedVideo} from "../model/published-video";
import {APIClient} from "../../../core/api/api-client";
import {Participation} from "../model/participation";

@Injectable({
  providedIn: 'root'
})
export class AttendanceService {

  constructor(private api: APIClient) {}

  sendPlaybackDuration(video : PublishedVideo, playbackDuration: number, eventType: AttendanceUpdateType, viewId: string) {

    let body = new AttendanceUpdate(eventType, viewId, playbackDuration);
    let attendanceLink = video.attendanceLink();
    this.api.post(attendanceLink, body, Participation).subscribe(participation => {
      environment.loggingEnabled && console.log("Attendance logged", participation);
    })
  }

}

