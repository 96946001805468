import {Injectable} from "@angular/core";
import {APIClient} from "../api/api-client";
import {Observable, of} from "rxjs";
import {Authentication} from "../model/authentication";
import {map, mergeMap} from 'rxjs/operators';
import {environment} from "../../../environments/environment";
import {ApplicationStateService} from "../state/application-state-service";
import {ApplicationState} from "../state/appilication-state";
import {APIResponse} from "../model/api-response";
import {Introspection} from "../model/introspection";
import {parseTemplate} from "../api/hal/templates";

export const REL_OAUTH_TOKEN = 'oauth:token';
export const REL_INTROSPECT_TOKEN = 'oauth:introspect';
export const REL_REVOKE_TOKEN = 'oauth:revoke';
export const REL_RESET_PASSWORD= 'customer:recovery';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor(private readonly client: APIClient,
    private readonly state: ApplicationStateService) {
  }

  public login(username: string, password: string): Observable<boolean> {
    return this.requestTokens(true, (body : { [key: string] : string; }) => {
      body['username'] = username;
      body['password'] = password;
      body['grant_type'] = 'password';
      body['scope'] = 'customer';
    });
  }

  refresh(refreshToken: string) : Observable<boolean> {
    return this.requestTokens(true, (body : { [key: string] : string; }) => {
      body['refresh_token'] = refreshToken;
      body['grant_type'] = 'refresh_token';
    });
  }


  private requestTokens(forceRefresh: boolean, populateBody: (body: { [p: string]: string }) => void) : Observable<boolean> {
    const current = this.state.application().current();
    if (current.isAuthenticated() && !forceRefresh) return of(true);
    if (!current.isInitialized()) {
      throw new Error("API entry not initialized: cannot determine login endpoint");
    }
    if (!current.api().linksTo(REL_OAUTH_TOKEN)) {
      throw new Error("API entry response does not contain link to obtain OAuth2 token");
    }
    const body = {'client_id': environment.client_id, 'client_secret': environment.client_secret}
    populateBody(body);
    return this.client.post<Authentication>(current.api().link(REL_OAUTH_TOKEN).href, body, Authentication)
      .pipe(
        mergeMap((authentication: Authentication) => {
          // on successful login store authentication in state and invalidate previous API entry response
          this.state.application().next(new ApplicationState(authentication, authentication.currentUser(), undefined));
          // fetch new API entry with authentication
          return this.client.initialize().pipe(map((state: ApplicationState) => {
            return state.isAuthenticated();
          }));
        })
      )
  }

  public logout(): Observable<boolean> {
    const current = this.state.application().current();
    if (!current.isAuthenticated()) return of(true);

    if (current.isInitialized()) {
      if (current.api().linksTo(REL_REVOKE_TOKEN)) {
        const link = current.api().link(REL_REVOKE_TOKEN).href.replace("{?token}", "?token=" + current.auth().access_token);
        return this.client.post<APIResponse>(link, {}, APIResponse)
          .pipe(
            mergeMap((response: APIResponse) => {
              if (response.status != 200) {
                console.log("Failed to request server-side logout: ", response);
              }
              // token invalidated, now clear state and re-initialize client with new API entry
              return this.clearState();
            })
          )
      } else {
        return this.clearState();
      }
    }

    this.state.clear();
    return of(true);
  }

  clearState() : Observable<boolean> {
    this.state.clear();
    console.log("Current user successfully logged out");
    return this.client.initialize().pipe(map((state: ApplicationState) => { return !state.isAuthenticated(); }));
  }


  resetPassword(email: string) : Observable<boolean> {
    const current = this.state.application().current();
    if (current.isAuthenticated()) return of(false);
    if (!current.isInitialized()) {
      throw new Error("API entry not initialized: cannot determine login endpoint");
    }
    if (!current.api().linksTo(REL_RESET_PASSWORD)) {
      throw new Error("API entry response does not contain link to reset password");
    }
    const body = { email }
    return this.client.post<APIResponse>(current.api().link(REL_RESET_PASSWORD).href, body, APIResponse).pipe(map(response => {
      if (response.status == 200) return true;
      console.log("Failed to request password reset: ", response);
      throw new Error(response.summary);
    }));
  }

  isAuthenticated() : Observable<boolean> | boolean {
    let currentState = this.state.application().current();
    if (!currentState.isAuthenticated()) return false;
    const auth = currentState.auth();
    if (auth.isExpired()) {
      if (!auth.refresh_token) {
        this.state.application().clear();
        return false;
      }
      this.refresh(auth.refresh_token).pipe(map(success => {
        if (!success) {
          return this.clearState().pipe(map(loggedOut => !loggedOut));
        }
        return true;
      }))
    }
    return true;
  }

  validate(token: string): Observable<boolean> {
    const current = this.state.application().current();
    console.log('current:', current)
    if (!current.isInitialized()) {
      return this.client.initialize().pipe(
        mergeMap((state: ApplicationState) => {
          if (!state.isInitialized()) {
            throw new Error("API entry not initialized: cannot determine login endpoint");
          }
          return this.performValidation(token);
        })
      );
    }
    return this.performValidation(token);
  }

  private performValidation(token: string): Observable<boolean> {
    const current = this.state.application().current();
    if (!current.api().linksTo(REL_INTROSPECT_TOKEN)) {
      throw new Error("API entry response does not contain link to obtain OAuth2 token");
    }
    let link = current.api().link(REL_INTROSPECT_TOKEN).href;
    return this.client.post<Introspection>(parseTemplate(link).expand("token", token), null, Introspection)
      .pipe(
        mergeMap((introspection: Introspection) => {
          if (!introspection.active) return of(false);
          console.log("Access token successfully validated for the current user " + introspection.username);
          this.state.application().next(new ApplicationState(introspection.asAuthentication(token), introspection.currentUser(), undefined));
          return this.client.initialize().pipe(map((state: ApplicationState) => state.isAuthenticated()));
        })
      );
  }
}
