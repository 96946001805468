import {NgModule} from "@angular/core";
import {BrowserModule} from "@angular/platform-browser";
import {HttpClientModule} from "@angular/common/http";
import {OnDemandModule} from "../../features/ondemand/ondemand.module";
import {EmbeddedLibraryPage} from "./library/embedded-library.page";
import {HistoryModule} from "../../features/history/history.module";
import {EmbeddedHistoryPage} from "./history/embedded-history.page";

@NgModule({
  declarations: [
    EmbeddedLibraryPage,
    EmbeddedHistoryPage
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    OnDemandModule,
    HistoryModule
  ]
})
export class EmbeddedPagesModule { }
