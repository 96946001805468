<div *ngIf="!isSmallScreen">
  <div *ngIf="!loading" class="user">
    <div class="title">
      <h1>{{title}}</h1>
      <p>{{subtitle}}</p>
    </div>

    <aside ngClass="{{isPaid() ? 'paid' : ''}}">
      <span class="note">{{isTrial() ? "Dein kostenloses Probetraining" : "Deine aktuelle Mitgliedschaft"}}</span>
      <em>{{subscriptionLevel()}}</em>
      <span *ngIf="isTrial() && !!expires()">&nbsp; endet in {{expires()}} Tagen</span>
    </aside>
  </div>

  <div *ngIf="loading" class="loading"><span class="icon">💓</span></div>
</div>

<div *ngIf="isSmallScreen" class="mobile-header">
  <div class="mobile-header__clickable-area">
    <img src="/assets/images/logo-icon.svg" width="42" alt="150 Minuten logo" />
    <h2>MEDIATHEK-TRAININGS</h2>
  </div>

  <button (click)="openMenu()">Menü</button>
</div>

<div class="mobile-menu-overlay" id="mobileMenu">
  <div class="mobile-menu-pannel">
    <img src="/assets/images/logo.svg" width="130" height="45" alt="150 Minuten logo" />
    <button class="close-btn" (click)="closeMenu()">×</button>
  </div>
  <div class="mobile-menu-content">

    <div>
      <ul class="mobile-menu-list">
        <li><a [routerLink]="['/library/videos']" routerLinkActive="active">Mediathek-Trainings</a></li>
        <li><a [routerLink]="['/history']" routerLinkActive="active">Traininghistorie</a></li>
      </ul>
    </div>
  </div>

  <div></div>
</div>
