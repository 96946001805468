import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {HeaderDataService} from "../../../../core/state/header-data.service";

@Component({
  selector: 'library-page',
  templateUrl: './library.page.html',
  styleUrls:[ './library.page.scss']
})
export class LibraryPage implements OnInit {

  constructor(header : HeaderDataService) {
    header.update(
      "MEDIATHEK-TRAININGS JEDERZEIT",
      "Trainiere mit unseren aufgezeichneten Mediathek-Trainings, wann immer es Dir passt. Ganz unten findest Du Wiederholungen einiger unserer Live-Trainings."
    );
  }

  ngOnInit(): void {
  }

}
