import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APIClient } from "../../../core/api/api-client";
import { ApplicationStateService } from "../../../core/state/application-state-service";
import { PublishedVideo, REL_ADD_FAVORITE, REL_REMOVE_FAVORITE } from "../model/published-video";
import { parseTemplate } from "../../../core/api/hal/templates";
import { PublishedVideoQuery } from "../model/published-video-query";
import { PublishedVideoList } from "../model/published-video-list";
import { ApplicationState } from "../../../core/state/appilication-state";
import { APIResponse } from "../../../core/model/api-response";
import { map, switchMap } from "rxjs/operators";

export const REL_ON_DEMAND_CONTENT = "content:on-demand";
export const REL_FIND_VIDEO = "content:find-by-id";

const ERR_API_NOT_AVAILABLE = "API not fully initialized";

@Injectable({
  providedIn: 'root'
})

export class PublishedVideoService {

  private searchLink: string | undefined;
  private allVideosLink: string | undefined;

  constructor(
    private client: APIClient,
    private state: ApplicationStateService
  ) {
    state.application().asObservable().subscribe(newState => this.updateState(newState));
    this.updateState(state.application().current());
  }

  private updateState(newState: ApplicationState) {
    this.searchLink = undefined;
    this.allVideosLink = undefined;
    if (newState && newState.isInitialized()) {
      if (newState.api().linksTo(REL_FIND_VIDEO)) {
        this.searchLink = newState.api().link(REL_FIND_VIDEO).href;
      }
      if (newState.api().linksTo(REL_ON_DEMAND_CONTENT)) {
        this.allVideosLink = newState.api().link(REL_ON_DEMAND_CONTENT).href;
      }
    }
  }

  findAll(query: PublishedVideoQuery): Observable<PublishedVideoList> {
    if (this.allVideosLink === undefined) throw new Error(ERR_API_NOT_AVAILABLE);
    return this.client.get(query.expand(this.allVideosLink), PublishedVideoList);
  }

  findByExternalId(externalId: string): Observable<PublishedVideo | undefined> {
    if (this.allVideosLink === undefined) throw new Error(ERR_API_NOT_AVAILABLE);
    return this.client.get(parseTemplate(this.allVideosLink).expand("externalId", externalId), PublishedVideoList)
      .pipe(map((list: PublishedVideoList) => {
        let videos = list.elements();
        if (!!videos && videos.length > 0) return videos[0];
        return undefined;
      }));
  }

  get(id: string): Observable<PublishedVideo> {
    if (this.searchLink === undefined) throw new Error(ERR_API_NOT_AVAILABLE);
    let href = parseTemplate(this.searchLink).expand("uuid", id);
    return this.client.get(href + '?embed=trainer,content:assigned-tags', PublishedVideo);
  }

  addToFavorites(video: PublishedVideo): Observable<boolean> {
    if (!video.linksTo(REL_ADD_FAVORITE)) throw new Error(ERR_API_NOT_AVAILABLE);
    let href = video.link(REL_ADD_FAVORITE).href;
    return this.client.put(href, {}, APIResponse).pipe(map(response => response.isSuccess()));
  }

  removeFromFavorites(video: PublishedVideo): Observable<boolean> {
    if (!video.linksTo(REL_REMOVE_FAVORITE)) throw new Error(ERR_API_NOT_AVAILABLE);
    let href = video.link(REL_REMOVE_FAVORITE).href;
    return this.client.delete(href, APIResponse).pipe(map(response => response.isSuccess()));
  }

  canToggleFavorite(video: PublishedVideo): boolean {
    return (video.linksTo(REL_ADD_FAVORITE) || video.linksTo(REL_REMOVE_FAVORITE));
  }

  toggleFavorite(video: PublishedVideo): Observable<PublishedVideo> {
    if (video.isFavorite()) {
      return this.removeFromFavorites(video).pipe(
        switchMap(() => this.get(video.id))
      );
    } else {
      return this.addToFavorites(video).pipe(
        switchMap(() => this.get(video.id))
      );
    }
  }
}
