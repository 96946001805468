import {NgModule} from "@angular/core";
import {BrowserModule} from "@angular/platform-browser";
import {HttpClientModule} from "@angular/common/http";
import {HistoryPage} from "./ui/history/history.page";
import {HistoryViewComponent} from "./ui/history/view/history-view.component";


@NgModule({
  declarations: [
    HistoryPage,
    HistoryViewComponent
  ],
  exports: [
    HistoryPage,
    HistoryViewComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule
  ]
})
export class HistoryModule { }
