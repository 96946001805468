import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {APIClient} from "../../../core/api/api-client";
import {ApplicationStateService} from "../../../core/state/application-state-service";
import {TrainingHistory} from "../model/training-history";

export const REL_MY_HISTORY = "my:history";

@Injectable({
  providedIn: 'root'
})
export class HistoryService {

  constructor(private client: APIClient,
              private readonly state : ApplicationStateService) { }

  fetchHistoryWithToken(): Observable<TrainingHistory> {
    const href = this.state.application().current().expectFullyInitialized().entry?.link(REL_MY_HISTORY).href;
    if (!!href) {
      return this.client.get(href, TrainingHistory);
    } else {
      throw new Error("Cannot request training history: API is not available");
    }
  }
}
