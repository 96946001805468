import {Injectable} from "@angular/core";
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {Observable, throwError} from "rxjs";
import {environment} from "../../../environments/environment";
import {catchError, map} from 'rxjs/operators';
import {ApiEntry} from "../model/api-entry";
import {ApplicationStateService} from "../state/application-state-service";
import {ApplicationState} from "../state/appilication-state";

@Injectable({
  providedIn: 'root'
})
export class APIClient {

  constructor(
    private httpClient: HttpClient,
    private state: ApplicationStateService) {
  }

  public get<T>(uri: string, ctor: new (json: T) => any): Observable<T> {
    const headers = this.authenticateIfRequired();
    return this.httpClient.get(uri, { headers }).pipe(
      catchError((error: any) => {
        console.log(uri, error);
        return throwError(() => error);
      }),
      map((response: any) => { return new ctor(response); })
    );
  }

  public post<T>(uri: string, body: any, ctor: new (json: any) => T, params?: HttpParams | { [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>; }): Observable<T> {
    const headers = this.authenticateIfRequired();
    const options = params ? { headers, params } : { headers };
    return this.httpClient.post(uri, body, options)
      .pipe(
        this.onErrorResponse(),
        map((response: any) => { return new ctor(response); })
      );
  }

  public put<T>(uri: string, body: any, ctor: new (json: any) => T): Observable<T> {
    const headers = this.authenticateIfRequired();
    return this.httpClient.put(uri, body, { headers })
      .pipe(
        this.onErrorResponse(),
        map((response: any) => { return new ctor(response); })
      );
  }

  public patch<T>(uri: string, body: any, ctor: new (json: any) => T): Observable<T> {
    const headers = this.authenticateIfRequired();
    return this.httpClient.patch(uri, body, { headers })
      .pipe(
        this.onErrorResponse(),
        map((response: any) => { return new ctor(response); })
      );
  }

  public delete<T>(uri: string, ctor: new (json: any) => T): Observable<T> {
    const headers = this.authenticateIfRequired();
    return this.httpClient.delete(uri, { headers })
      .pipe(
        this.onErrorResponse(),
        map((response: any) => { return new ctor(response); })
      );
  }

  public initialize(): Observable<ApplicationState> {
    return this.get<ApiEntry>(environment.api + '/v1/start', ApiEntry).pipe(
      map((response: ApiEntry) => {
        return this.state.application().update(state => state.onUpdate(response));
      }));
  }

  private onErrorResponse() {
    return catchError((error: any) => {
      // TODO: add handling specific error responses, e.g. 403
      return throwError(() => error);
    });
  }

  private authenticateIfRequired(): HttpHeaders {
    const currentState = this.state.application().current();
    let authorization = {};

    if (currentState.isAuthenticated()) {
      const accessToken = currentState.auth().access_token;
      if (!accessToken) {
        console.log(currentState);
      }
      authorization = {
        'Authorization': "Bearer " + accessToken
      };
    }
    return new HttpHeaders(authorization);
  }
}
